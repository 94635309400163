import { getMultipleSelectValue } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/groups-statuses-and-types/util";
import { colSAPContact } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/attributes/config";
import {
  EHasCommunityGroup,
  IPromptObsolete,
  ePreferredContactMethod,
} from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/attributes/model";
import { checkTypeContact } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/util";
import {
  EKeysOfNewContactSteps,
  MODE_CONTACT,
} from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import { SAP } from "@app/products/property/contacts-central-names/list/components/dialogs/update-contact/model";
import { nameOfLov } from "@app/products/property/model";
import { convertValueLOVToNumber } from "@app/products/property/util";
import { DATETIME_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { DTO_LOV, DTO_LOV_Number } from "@common/models/odataResponse";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCInputEmail } from "@components/cc-input-email/_index";
import { validateEmail } from "@components/cc-input-email/util";
import { CCInputPhone } from "@components/cc-input-phone/_index";
import { useIsEnableValidatePhoneNumber } from "@components/cc-input-phone/hook/useIsEnableValidatePhoneNumber";
import { EModePhoneNumber } from "@components/cc-input-phone/model";
import { validatePhoneNumber } from "@components/cc-input-phone/util";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import {
  ComboBoxChangeEvent,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import {
  SwitchChangeEvent,
  TextAreaChangeEvent,
} from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";

const nameOfSAP = nameOfFactory<SAP>();
export const AttributesFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
      isActro: false,
      isLLS: false,
      contactLOVs: undefined,
      isUpdate: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const [isShowObsoletePrompt, setIsShowObsoletePrompt] = useState<
      IPromptObsolete | undefined
    >();
    const isEnableValidatePhoneNumber = useIsEnableValidatePhoneNumber();
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const [preferredContactMethod, setPreferredContactMethod] =
      useState<number>(
        getFieldValue("Preferred_Method") ?? ePreferredContactMethod.None
      );
    const typeValue = valueGetter(`${EKeysOfNewContactSteps.Person}.Type`);

    const contactLovs = useMemo(() => {
      const gender = options?.contactLOVs?.Gender ?? [];
      const preferredMethodData = options?.contactLOVs?.PreferredMethod ?? [];
      const contactIdentifies = convertValueLOVToNumber<DTO_LOV>(
        options?.contactLOVs?.CommunityGroup ?? [],
        nameOfLov("Code")
      );
      const aboriginal = convertValueLOVToNumber<DTO_LOV>(
        options?.contactLOVs?.Aboriginal ?? [],
        nameOfLov("Code")
      );
      const hasCommunityGroup = convertValueLOVToNumber<DTO_LOV>(
        options?.contactLOVs?.HasCommunityGroup ?? [],
        nameOfLov("Code")
      );
      return {
        gender,
        preferredMethodData,
        contactIdentifies,
        aboriginal,
        hasCommunityGroup,
      };
    }, [options?.contactLOVs]);
    const dataSAPCustomerNumbers: SAP[] = getFieldValue("SAP_Numbers") ?? [];
    const handleDataChangeGrid = (dataRow: SAP, fieldName: string) => {
      const numberId = dataRow?.Entity_SAP_Id;
      let newSAP = [...dataSAPCustomerNumbers];
      newSAP = newSAP.map((item: SAP) => {
        if (
          item.Entity_SAP_Id === numberId &&
          fieldName === nameOfSAP("SAP_Number")
        ) {
          return {
            ...item,
            SAP_Number: dataRow?.SAP_Number ?? null,
          };
        }
        return item;
      });
      onChange(nameOf("SAP_Numbers"), { value: newSAP });
    };

    const isFieldMandatory = useCallback(
      (ePreferredContactMethod: ePreferredContactMethod) => {
        return preferredContactMethod === ePreferredContactMethod;
      },
      [preferredContactMethod]
    );

    const isContactActive = getFieldValue("Is_Active");
    const officerIsAdministrator = getFieldValue(
      "_option.OfficerIsAdministrator"
    );

    const isShowFields = useMemo(() => {
      const typeValueContact = checkTypeContact(typeValue);
      return (
        typeValueContact !== MODE_CONTACT.ORGANISATION &&
        typeValueContact !== MODE_CONTACT.INCORPORATED_BODY &&
        typeValueContact !== MODE_CONTACT.PARTNERSHIP
      );
    }, [typeValue]);

    return (
      <>
        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            {!options?.isLLS && (
              <>
                <div className="cc-field">
                  <CCLabel title="Date of birth" />
                  <Field
                    autoComplete="off"
                    name={nameOf("DateOfBirth")}
                    component={CCDatePicker}
                    format={DATE_FORMAT.DATE_CONTROL}
                    disabled={options?.isReadOnly}
                  />
                </div>
                <div className="cc-field">
                  <CCLabel title="Date of death" />
                  <Field
                    name={nameOf("DateOfDeath")}
                    component={CCDatePicker}
                    format={DATE_FORMAT.DATE_CONTROL}
                    disabled={options?.isReadOnly}
                  />
                </div>
              </>
            )}
            {isShowFields && (
              <>
                <div className="cc-field">
                  <CCLabel title="Occupation" />
                  <Field
                    name={nameOf("Occupation")}
                    component={CCInput}
                    placeholder={"Occupation"}
                    readOnly={options?.isReadOnly}
                  />
                </div>
                <div className="cc-field">
                  <CCLabel title="Gender" />
                  <Field
                    name={nameOf("Gender")}
                    textField={nameOfLov("Name")}
                    dataItemKey={nameOfLov("Code")}
                    data={contactLovs.gender}
                    component={CCSearchComboBox}
                    isUseDefaultOnchange
                    disabled={options?.isReadOnly}
                  />
                </div>
                <div className="cc-field">
                  <CCLabel
                    title="Does the contact identify as Aboriginal, Torres Strait Islander?"
                    isMandatory
                  />
                  <Field
                    name={nameOf("Aboriginal")}
                    textField={nameOfLov("Name")}
                    dataItemKey={nameOfLov("Code")}
                    data={contactLovs.aboriginal}
                    component={CCSearchComboBox}
                    isUseDefaultOnchange
                    disabled={options?.isReadOnly}
                    validator={requiredValidator}
                  />
                </div>
              </>
            )}
          </div>
          {isShowFields && (
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <CCLabel
                  title="Does the contact identify with a Community Group?"
                  isMandatory
                />
                <Field
                  name={nameOf("HasCommunityGroup")}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  data={contactLovs.hasCommunityGroup}
                  component={CCSearchComboBox}
                  disabled={options?.isReadOnly}
                  validator={requiredValidator}
                  value={getDropdownValue(
                    valueGetter(nameOf("HasCommunityGroup")),
                    contactLovs.hasCommunityGroup,
                    nameOfLov("Code")
                  )}
                  onChange={(event: ComboBoxChangeEvent) => {
                    const selectedValue = event.value?.[nameOfLov("Code")];
                    // Reset"Community group"
                    if (selectedValue !== EHasCommunityGroup.Yes) {
                      onChange(nameOf("Community_Group_Ids"), { value: [] });
                    }
                    onChange(nameOf("HasCommunityGroup"), {
                      value: selectedValue,
                    });
                  }}
                />
              </div>
              {/* Only show when "Does the contact identify with a Community
            Group?"==="Yes"  */}
              {getFieldValue("HasCommunityGroup") === EHasCommunityGroup.Yes ? (
                <div className="cc-field">
                  <CCLabel title="Community group" />
                  <Field
                    name={nameOf("Community_Group_Ids")}
                    textField={nameOfLov("Name")}
                    dataItemKey={nameOfLov("Code")}
                    data={contactLovs.contactIdentifies}
                    component={CCMultiSelectDropdown}
                    disabled={options?.isReadOnly}
                    onChange={(event: MultiSelectChangeEvent) => {
                      let value = event?.value;
                      if (value?.length) {
                        value = value.map((item: DTO_LOV_Number) => item.Code);
                      }
                      onChange(nameOf("Community_Group_Ids"), {
                        value: value,
                      });
                    }}
                    value={getMultipleSelectValue(
                      getFieldValue("Community_Group_Ids"),
                      contactLovs.contactIdentifies,
                      nameOfLov("Code")
                    )}
                  />
                </div>
              ) : null}
            </div>
          )}
          <div className="cc-field-group">
            <CCLabel title="Contact information" />
            <div className="cc-form-cols-2 cc-custom-sub-panel-bar">
              <div className="cc-field">
                <CCLabel
                  title="Private"
                  isMandatory={isFieldMandatory(
                    ePreferredContactMethod.Home_Phone
                  )}
                />
                <Field
                  name={nameOf("PhoneNumber_Private")}
                  placeholder={"Private"}
                  readOnly={options?.isReadOnly}
                  component={CCInputPhone}
                  validator={
                    isFieldMandatory(ePreferredContactMethod.Home_Phone)
                      ? [validatePhoneNumber, requiredValidator]
                      : validatePhoneNumber
                  }
                  onChangeForm={onChange}
                  skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                  mode={EModePhoneNumber.Private}
                />
              </div>
              <div className="cc-field">
                <CCLabel
                  title="Business"
                  isMandatory={isFieldMandatory(
                    ePreferredContactMethod.Work_Phone
                  )}
                />
                <Field
                  name={nameOf("PhoneNumber_Business")}
                  placeholder={"Business"}
                  readOnly={options?.isReadOnly}
                  component={CCInputPhone}
                  validator={
                    isFieldMandatory(ePreferredContactMethod.Work_Phone)
                      ? [validatePhoneNumber, requiredValidator]
                      : validatePhoneNumber
                  }
                  onChangeForm={onChange}
                  skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                  mode={EModePhoneNumber.Business}
                />
              </div>
              <div className="cc-field">
                <CCLabel
                  title="Mobile"
                  isMandatory={isFieldMandatory(ePreferredContactMethod.Mobile)}
                />
                <Field
                  name={nameOf("PhoneNumber_Mobile")}
                  placeholder={"Mobile"}
                  readOnly={options?.isReadOnly}
                  component={CCInputPhone}
                  validator={
                    isFieldMandatory(ePreferredContactMethod.Mobile)
                      ? [validatePhoneNumber, requiredValidator]
                      : validatePhoneNumber
                  }
                  onChangeForm={onChange}
                  skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                  mode={EModePhoneNumber.Mobile}
                />
              </div>
              <div className="cc-field">
                <Field
                  label="Email address"
                  name={nameOf("Email_Address")}
                  placeholder={"Email address"}
                  readOnly={options?.isReadOnly}
                  component={CCInputEmail}
                  validator={
                    isFieldMandatory(ePreferredContactMethod.Email)
                      ? [validateEmail, requiredValidator]
                      : validateEmail
                  }
                  onChangeForm={onChange}
                  tooltip={
                    isFieldMandatory(ePreferredContactMethod.Email) ? (
                      <CCTooltip type="validator" position="right" />
                    ) : null
                  }
                />
              </div>
              <div className="cc-field">
                <CCLabel
                  title="Fax"
                  isMandatory={isFieldMandatory(ePreferredContactMethod.TTY)}
                />
                <Field
                  name={nameOf("PhoneNumber_Fax")}
                  placeholder={"Fax"}
                  readOnly={options?.isReadOnly}
                  component={CCInputPhone}
                  validator={
                    isFieldMandatory(ePreferredContactMethod.TTY)
                      ? [validatePhoneNumber, requiredValidator]
                      : validatePhoneNumber
                  }
                  onChangeForm={onChange}
                  skipValidatePhoneMask={!isEnableValidatePhoneNumber}
                  mode={EModePhoneNumber.Fax}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Preferred name" />
                <Field
                  name={nameOf("Preferred_Name")}
                  component={CCInput}
                  placeholder={"Preferred name"}
                  readOnly={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Preferred contact method" isMandatory />
                <Field
                  name={nameOf("Preferred_Method")}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  data={contactLovs.preferredMethodData}
                  component={CCSearchComboBox}
                  value={getDropdownValue(
                    valueGetter(nameOf("Preferred_Method"))?.toString(),
                    contactLovs.preferredMethodData,
                    nameOfLov("Code")
                  )}
                  onChange={(event: ComboBoxChangeEvent) => {
                    setPreferredContactMethod(+event.value?.Code);
                    onChange(nameOf("Preferred_Method"), {
                      value: event.value?.Code ?? null,
                    });
                  }}
                  disabled={options?.isReadOnly}
                  validator={requiredValidator}
                />
              </div>
            </div>
          </div>
          {!options?.isActro && !options?.isLLS && (
            <div className="cc-field-group">
              <CCLabel title="Electoral commission" />
              <div className="cc-custom-sub-panel-bar">
                <div className="cc-form-cols-2">
                  <div className="cc-field">
                    <CCLabel title="Person number" />
                    <Field
                      name={nameOf("ElectoralCommission_PersonNumber")}
                      component={CCInput}
                      placeholder={"Person number"}
                      readOnly={options?.isReadOnly}
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Update date" />
                    <Field
                      name={nameOf("ElectoralCommission_UpdateDate")}
                      component={CCDatePicker}
                      format={DATE_FORMAT.DATE_CONTROL}
                      disabled={options?.isReadOnly}
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Voter registration date" />
                    <Field
                      name={nameOf("ElectoralCommission_VoterRegistrationDate")}
                      component={CCDatePicker}
                      format={DATE_FORMAT.DATE_CONTROL}
                      disabled={options?.isReadOnly}
                    />
                  </div>

                  <div className="cc-field">
                    <CCLabel title="Silent enrolment" />
                    <Field
                      name={nameOf("Is_Silent_Enrolment")}
                      component={CCSwitch}
                      placeholder={"Silent enrolment"}
                      checked={getFieldValue("Is_Silent_Enrolment")}
                      disabled={options?.isReadOnly}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="cc-field-group">
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <CCLabel title="Alias" />
                <Field
                  name={nameOf("_option.AlsoKnownAsNames")}
                  rows={3}
                  component={CCTextArea}
                  onChange={(e: TextAreaChangeEvent) => {
                    onChange(nameOf("_option.AlsoKnownAsNames"), {
                      value: e?.value,
                    });
                    if (e?.value) {
                      const value = e?.value;
                      onChange(nameOf("AlsoKnownAsNames"), {
                        value:
                          value?.length > 0
                            ? value?.split("\n")?.filter((item) => item?.length)
                            : [],
                      });
                    }
                  }}
                  placeholder={"Alias"}
                  readOnly={options?.isReadOnly}
                />
              </div>
            </div>
          </div>
          {options?.isUpdate && (
            <div className="cc-field-group">
              <div className="cc-form-cols-3">
                <CCValueField
                  label="Last changed on"
                  value={getFieldValue("Changed_On")}
                  format={DATETIME_FORMAT.DATETIME}
                />
                <CCValueField
                  label="Last changed by"
                  value={getFieldValue("Changed_By")}
                />
                <CCValueField label="Contact ID" value={getFieldValue("Id")} />
              </div>
            </div>
          )}
          <div className="cc-field-group">
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <CCLabel title="Applicant number" />
                <Field
                  name={nameOf("Applicant_Reference")}
                  component={CCInput}
                  placeholder={"Applicant number"}
                  readOnly={options?.isReadOnly}
                  maxLength={100}
                />
              </div>
            </div>
            {options?.isLLS && options?.isUpdate ? (
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <CCLabel title="SAP customer numbers" />
                  <CCGrid
                    readOnly={options?.isReadOnly}
                    data={dataSAPCustomerNumbers ?? []}
                    columnFields={colSAPContact}
                    primaryField={nameOfSAP("Entity_SAP_Id")}
                    editableMode="cell"
                    onDataRowChange={handleDataChangeGrid}
                  />
                </div>
              </div>
            ) : null}
            {options?.isUpdate ? (
              <div className="cc-form-cols-2">
                <div className="cc-field">
                  <CCLabel title="Is obsolete" />
                  <Field
                    name={nameOf("Is_Obselete")}
                    component={CCSwitch}
                    checked={getFieldValue("Is_Obselete")}
                    disabled={
                      options?.isReadOnly ||
                      isContactActive ||
                      !officerIsAdministrator
                    }
                    onChange={(event: SwitchChangeEvent) => {
                      setIsShowObsoletePrompt({ isObsolete: event?.value });
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </section>
        {isShowObsoletePrompt ? (
          <ConfirmDialog
            title="Confirmation"
            subMessage={
              "Are you sure wish to make this contact obsolete? You can not reverse this action once completed."
            }
            onClosePopup={() => setIsShowObsoletePrompt(undefined)}
            onConfirmYes={() => {
              onChange(nameOf("Is_Obselete"), {
                value: isShowObsoletePrompt?.isObsolete,
              });
              setIsShowObsoletePrompt(undefined);
            }}
          />
        ) : null}
      </>
    );
  }
);
