import { TFormName } from "@common/stores/flexible-form/model";
import { flexibleFormStoreInstance } from "@common/stores/flexible-form/store";
import { useCallback, useRef } from "react";

export const useFlexibleFormState = (formNameProp?: TFormName) => {
  const formRef = useRef<any>(null);
  const formName = formNameProp ?? "GeneralForm";
  const initialDataForm = flexibleFormStoreInstance.dataForms?.[formName];

  const setFormRef = useCallback(
    (ref) => {
      formRef.current = ref;
      if (ref) {
        flexibleFormStoreInstance.setFormProps({
          formName,
          formProps: {
            onChange: ref.onChange,
            onSubmit: ref.onSubmit,
            valueGetter: ref.valueGetter,
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formRef.current, initialDataForm]
  );

  return { setFormRef, initialDataForm };
};
