import { eventEmitter } from "@/App";
import { history } from "@/AppRoutes";
import { CCJournalEventType } from "@app/core/journal/util";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { LOCAL_LAWS_PERMIT_ROUTE } from "@app/products/local-laws/[id]/constant";
import {
  Permit,
  Permit_Status,
  Svc_Permit,
  Svc_Permit_Workflow_ProvideReason,
} from "@app/products/local-laws/[id]/model";
import { postRefusePermit } from "@app/products/local-laws/components/toolbar/buttons/refuse-permit/api";
import { ReasonDialog } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/_index";
import { ProvideReason } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/model";
import { isSuccessResponse } from "@common/apis/util";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
interface IRefusePermitButtonProps {
  isDisabled?: boolean;
}

export const RefusePermitButton = observer(
  ({ isDisabled }: IRefusePermitButtonProps) => {
    //#region STORE ========/
    const {
      dataForms,
      isLoadingForm,
      submitFormGetData,
      setMiddlewareSubmitFormOptions,
      updateFormFields,
    } = useFlexibleFormStore();
    const { settings } = useCommonCoreStore();
    const { cancelToken } = useCancelRequest();
    const { pushNotificationPortal } = useNotificationPortalStore();
    //#endregion STORE =====/

    //#region STATES ========/
    const isNew = useIsNew();
    const [objReasonRefuse, setObjReasonRefuse] = useState<ProvideReason>();
    const [isShowWorkflowRefuse, setIsShowWorkflowRefuse] =
      useState<boolean>(false);
    const [isLoadingDialog, setIsLoadingDialog] = useState<boolean>(false);
    //#endregion STATES =====/

    const llpShowRefuseButton = getBoolValueSetting(
      settings[ECorporateSettingsField.LLP_ShowRefuseButton]
    );

    //#region DEFINED ========/
    const isVisible = useMemo(() => {
      const permitData = dataForms?.GeneralForm?.Permit as Permit;
      return (
        permitData?.Status_ENUM === Permit_Status.NewApplication ||
        (llpShowRefuseButton &&
          permitData?.Status_ENUM === Permit_Status.PermitLodged)
      );
    }, [llpShowRefuseButton, dataForms]);
    //#endregion DEFINED =====/

    //#region METHOD ========/
    const objRefuse = () => {
      const objReason = new ProvideReason();
      objReason.Title_Text = "Refuse Permit";
      objReason.ReasonLabel_Text = "Reason";
      objReason.Flag_ShowDate = false;

      setObjReasonRefuse(objReason);
    };

    const isOpenRefuseWfDialog = useMemo(() => {
      setObjReasonRefuse(undefined);
      if (isShowWorkflowRefuse) objRefuse();

      return isShowWorkflowRefuse;
    }, [isShowWorkflowRefuse]);

    const handleOnCloseDialog = () => {
      setIsShowWorkflowRefuse(false);
      setObjReasonRefuse(undefined);
    };

    const handleWorkflowRefuse = async (
      provideReason: ProvideReason,
      notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
    ) => {
      setMiddlewareSubmitFormOptions({ skipCheckModified: true });
      const generalForm = await submitFormGetData("GeneralForm");
      let permit: Permit = generalForm?.Permit;

      if (permit) {
        permit.TypeOfDescription = permit.TypeOfDescription ?? "";
        permit.ReferenceNumber = permit.ReferenceNumber ?? "";
        permit.FileNumber = permit.FileNumber ?? "";
        permit.InsurancePolicyNumber = permit.InsurancePolicyNumber ?? "";
        permit.Description = permit.Description ?? "";

        const responseRequest = {
          Permit: permit,
          ProvideReason: provideReason,
        } as Svc_Permit_Workflow_ProvideReason;

        setIsLoadingDialog(true);
        const response = await postRefusePermit(responseRequest, cancelToken());
        setIsLoadingDialog(false);
        if (isSuccessResponse(response) && response?.data?.ReturnObj) {
          const permitFormData = response?.data?.ReturnObj as Permit;
          //#region save data to store ========/
          if (isNew) {
            setIsShowWorkflowRefuse(false);
            setObjReasonRefuse(undefined);
            const urlRedirect =
              LOCAL_LAWS_PERMIT_ROUTE + "/" + permitFormData.Permit_ID;
            pushNotificationPortal({
              title: "Permit refused successfully.",
              type: "success",
              route: urlRedirect,
              callBack() {
                history.replace(urlRedirect);
              },
            });
          } else {
            updateFormFields("GeneralForm", {
              Permit: permitFormData,
            } as Svc_Permit);

            setIsShowWorkflowRefuse(false);
            setObjReasonRefuse(undefined);

            eventEmitter.emit(CCGridEventType.RefreshOData);
            eventEmitter.emit(CCJournalEventType.RefreshData);
            eventEmitter.emit(TabTableEventType.RefreshData);

            pushNotificationPortal({
              title: "Permit refused successfully.",
              type: "success",
            });
          }
        } else {
          notificationRef.current?.pushNotification({
            autoClose: false,
            title: "Deregister failed.",
            type: "error",
            description: response.data?.Errors ?? response.statusText,
          });
        }
      }
    };

    const handleClickButton = async () => {
      setMiddlewareSubmitFormOptions({ skipCheckModified: true });
      const dataForm = await submitFormGetData("GeneralForm");
      if (!isNil(dataForm?.Permit)) {
        setIsShowWorkflowRefuse(true);
      }
    };
    //#endregion METHOD =====/

    return (
      <>
        <CCNavButton
          title="Refuse permit"
          invisible={!isVisible}
          onClick={handleClickButton}
          disabled={isLoadingForm || isDisabled}
        />

        {isOpenRefuseWfDialog && (
          <ReasonDialog
            onClose={handleOnCloseDialog}
            onSubmit={(
              value: ProvideReason,
              notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
            ) => handleWorkflowRefuse(value, notificationRef)}
            isLoading={isLoadingDialog}
            initialData={objReasonRefuse}
          />
        )}
      </>
    );
  }
);
