import { AddCommentPropertyButton } from "@app/core/comments/components/buttons/add-comment/components/add-comment-property/_index";
import { PropertyComments } from "@app/core/comments/components/property-comments/_index";
import { MailMergeButtonManagePage } from "@app/core/new-mail-merge/buttons/mail-merge-manage-page/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { CancelCertificateButton } from "@app/products/property/certificates/[id]/components/action-bar/buttons/cancel-certificate/_index";
import { ModifyCertificateButton } from "@app/products/property/certificates/[id]/components/action-bar/buttons/modify-certificate/_index";
import { ReplaceCertificateButton } from "@app/products/property/certificates/[id]/components/action-bar/buttons/replace-certificate/_index";
import { CertificateAdverseAffections } from "@app/products/property/certificates/[id]/components/child-screens/adverse-affections";
import { CertificateBalances } from "@app/products/property/certificates/[id]/components/child-screens/balances/_index";
import { CertificateCompliance } from "@app/products/property/certificates/[id]/components/child-screens/compliance/_index";
import { CertificateDebtRecovery } from "@app/products/property/certificates/[id]/components/child-screens/debt-recovery/_index";
import { CertificateDeferments } from "@app/products/property/certificates/[id]/components/child-screens/deferments/_index";
import { PropertyCertificateForm } from "@app/products/property/certificates/[id]/components/child-screens/general/_index";
import { CertificateRatesDepartment } from "@app/products/property/certificates/[id]/components/child-screens/rates-department/_index";
import { CertificateResponses } from "@app/products/property/certificates/[id]/components/child-screens/responses/_index";
import { AORModifyComplianceButton } from "@app/products/property/certificates/[id]/components/forms/existed/components/action-bar/buttons/area-of-responsibility/compliance/_index";
import { AORModifyDebtRecoveryButton } from "@app/products/property/certificates/[id]/components/forms/existed/components/action-bar/buttons/area-of-responsibility/debt-recovery/_index";
import { AORModifyDefermentButton } from "@app/products/property/certificates/[id]/components/forms/existed/components/action-bar/buttons/area-of-responsibility/deferments/_index";
import { CreateCertificateDocumentButton } from "@app/products/property/certificates/[id]/components/forms/existed/components/action-bar/buttons/create-document-certificate/_index";
import { IssueDocumentCertificateButton } from "@app/products/property/certificates/[id]/components/forms/existed/components/action-bar/buttons/issue-document-certificate/_index";
import { CertificateDetailTab } from "@app/products/property/certificates/[id]/components/reference-sidebar/detail/_index";
import { CertificateMapTab } from "@app/products/property/certificates/[id]/components/reference-sidebar/map/_index";
import { CertificateStatus } from "@app/products/property/certificates/[id]/model";
import { useCertificateStore } from "@app/products/property/certificates/[id]/store";
import { NewCertificateButton } from "@app/products/property/certificates/components/action-bar/buttons/new-certificate/_index";
import { AddAttachmentPropertyButton } from "@app/products/property/components/action-bar/button/add-attachment/_index";
import { eDocumentTypeCategory } from "@app/products/property/components/action-bar/button/add-attachment/model";
import { CollectionsButton } from "@app/products/property/components/action-bar/button/collections/_index";
import { ViewDocumentButton } from "@app/products/property/components/action-bar/button/view-document/_index";
import { PropertyAssociations } from "@app/products/property/components/associations/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyActions } from "@app/products/property/components/child-screen/actions";
import { PropertyCollections } from "@app/products/property/components/child-screen/collections/_index";
import { PropertyDocuments } from "@app/products/property/components/child-screen/documents/_index";
import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { PropertyContactTab } from "@app/products/property/components/reference-sidebar/contact/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { isFieldVisible } from "@app/products/property/util";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { CommunityProperty } from "@common/stores/products/config";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { eAccordionType } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedPropertyCertificate = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState(true);
  const { id } = useParams<{ id: string }>();
  const {
    isLoading,
    responseLoadError,
    certificate,
    summary,
    isResponsesVisible,
    loadCertificate,
  } = useCertificateStore();
  const { currentUserInfo } = useGlobalStore();
  const { isLLS, isActro } = CommunityProperty.getFlagOfStates();

  const managePageUrl = window.location.pathname;
  const isDisabled: boolean = useMemo(
    () =>
      certificate?.Certificate_Status_Id === CertificateStatus.Cancelled ||
      certificate?.Certificate_Status_Id ===
        CertificateStatus.CertificateIssued,
    [certificate]
  );

  const bookmarkList: IBookmarkItem[] = [
    {
      Text:
        "Certificate " + certificate
          ? certificate?.Cert_Number +
            " - " +
            certificate?.Certificate_Type_Name
          : "",
      LinkUrl: managePageUrl,
      LinkText: `Property - Certificate - ${certificate?.CertificateId ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CommunityProperty,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_Certificate,
      Record_ID: certificate?.CertificateId ?? 0,
    },
  ];
  const listPanelBar: IPropertyPanelBar[] = [
    {
      title: "Actions and Workflows",
      component: (
        <PropertyActions
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_Certificate}
          componentNumber={eComponent.Certificate}
        />
      ),
      accordionType: eAccordionType.Actions_And_Workflows,
    },
    {
      title: "Adverse Affectations",
      component: <CertificateAdverseAffections />,
      isVisible: isFieldVisible(summary?.AdverseAffectionsVisibility),
    },
    // Requirement: https://dev.azure.com/openofficeau/Radium/_workitems/edit/12802/
    // {
    //   title: "Audit",
    //   component: (
    //     <PropertyAudit
    //       id={parseInt(id)}
    //       componentNumber={eComponent.Certificate}
    //     />
    //   ),
    // },
    { title: "Balances", component: <CertificateBalances /> },
    {
      title: "Comments",
      component: (
        <PropertyComments
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_Certificate}
        />
      ),
    },
    {
      title: "Collections",
      component: (
        <PropertyCollections
          componentId={parseInt(id)}
          componentNumber={eComponent.Certificate}
        />
      ),
    },
    {
      title: "Compliance",
      component: <CertificateCompliance />,
      isVisible: isActro,
    },
    {
      title: "Related",
      component: (
        <PropertyAssociations
          id={parseInt(id)}
          componentNumber={eComponent.Certificate}
        />
      ),
    },
    {
      title: "Documents",
      component: (
        <PropertyDocuments
          componentId={parseInt(id)}
          componentNumber={eComponent.Certificate}
        />
      ),
    },
    {
      title: "Debt recovery",
      component: <CertificateDebtRecovery />,
      isVisible: isActro,
    },
    {
      title: "Deferments",
      component: <CertificateDeferments />,
      isVisible: isActro,
    },
    {
      title: "Rates department",
      component: <CertificateRatesDepartment />,
      isVisible: isActro,
    },
    {
      title: "Responses",
      component: <CertificateResponses />,
      isVisible: isResponsesVisible,
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Certificate" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadCertificate(+id)}
        />
      ) : (
        <>
          <FormTitle
            title={
              "Certificate " + certificate
                ? certificate?.Cert_Number +
                  " - " +
                  certificate?.Certificate_Type_Name
                : ""
            }
            badge={certificate?.Certificate_Status_Name || ""}
          />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <CCNavButton title={"Certificate"} type="dropdown">
                  <ModifyCertificateButton isDisabled={isDisabled} />
                  <CancelCertificateButton
                    disabled={isDisabled}
                    certificateId={parseInt(id)}
                  />
                  <IssueDocumentCertificateButton disabled={isDisabled} />
                  <ReplaceCertificateButton />
                  <NewCertificateButton />
                </CCNavButton>
                <CCNavButton
                  title={"Area of responsibility"}
                  type="dropdown"
                  invisible={isLLS}
                >
                  <AORModifyComplianceButton disabled={isDisabled} />
                  <AORModifyDebtRecoveryButton disabled={isDisabled} />
                  <AORModifyDefermentButton disabled={isDisabled} />
                  <CCNavButton title={"Exceptions"} disabled={isDisabled} />
                </CCNavButton>
                <CollectionsButton
                  id={parseInt(id)}
                  propertyComponent={eComponent.Certificate}
                />
                <CCNavButton title={"Documents"} type="dropdown">
                  <CreateCertificateDocumentButton />
                  <ViewDocumentButton />
                </CCNavButton>
              </CCNavButton>,
              <CCNavButton title={"Add"} type="sub">
                <AddActionWithParentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Certificate}
                />
                <AddAttachmentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Certificate}
                  documentCategoryTypeId={
                    eDocumentTypeCategory.Certificate_Document
                  }
                />
                <AddCommentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Certificate}
                />
                <MailMergeButtonManagePage
                  productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
                  dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Certificate}
                  recordType={RECORDTYPE.CommunityProperty_Certificate}
                  isEmptyGridSelectedRow={!parseInt(id)}
                  selectedRecordIds={[parseInt(id)]}
                />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {certificate && (
                  <CCGeneralPanel component={<PropertyCertificateForm />} />
                )}
                <PropertyPanelBar
                  initialPanels={listPanelBar}
                  componentId={parseInt(id)}
                  componentNumber={eComponent.Certificate}
                />
              </div>
            </div>

            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    { title: "Details", component: <CertificateDetailTab /> },
                    {
                      title: "Map",
                      component: <CertificateMapTab />,
                      // 15755 - hide "Map" for all
                      isVisible: false,
                    },
                    {
                      title: "Contacts",
                      component: (
                        <PropertyContactTab
                          id={+id}
                          componentNumber={eComponent.Certificate}
                        />
                      ),
                    },
                    {
                      title: "History",
                      component: (
                        <PropertyHistoryTab
                          recordType={RECORDTYPE.CommunityProperty_Certificate}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
