import {
  getFromDate,
  getToDate,
} from "@app/products/ams/api/helpers/date-helper";
import {
  DTO_Workflow_Approval_Step,
  DTO_WorkflowHeader,
  EApprovalStatusName,
  ERefreshPageStep,
} from "@app/products/property/model";
import { isAfter } from "date-fns";
import { isNil, maxBy } from "lodash";

export const ERROR_MESSAGE_FROM_TO_DATE = `"To Date" must be greater than or equal to "From Date"`;

export const assessmentGISValidator = (
  gisData: any = [],
  fromDateField: string,
  toDateField: string
): string | undefined => {
  const isError = gisData.some((item: any) => {
    const fromDate = item?.[fromDateField]
      ? getFromDate(item?.[fromDateField])
      : null;
    const toDate = item?.[toDateField] ? getToDate(item?.[toDateField]) : null;
    return fromDate && toDate && isAfter(fromDate, toDate);
  });

  if (isError) {
    return ERROR_MESSAGE_FROM_TO_DATE;
  }

  return undefined;
};

export const isRefreshData = (
  workflowHeader: DTO_WorkflowHeader | undefined,
  isManagePage: boolean = true
): ERefreshPageStep | undefined => {
  // Refresh the manage page if finish or approve the workflow at Manage page
  // Need to check the Requires_Approval to identify the workflow to approve or not to refresh the manage page
  if (!isNil(workflowHeader) && isManagePage) {
    // workflowHeader?.Flags?.Requires_Approval = false => Finish stage
    if (!workflowHeader?.Flags?.Requires_Approval) {
      return ERefreshPageStep.Finish;
    }
    // If this is the last approval step => When user done the workflow, refresh the manage page
    const isLastStep =
      maxBy(
        workflowHeader?.WorkflowApprovals,
        (item: DTO_Workflow_Approval_Step) => {
          return item.WorkflowStepSequence;
        }
      )?.ApprovalStatusName === EApprovalStatusName.Current;
    if (isLastStep) {
      return ERefreshPageStep.Approve;
    }
  }
};
