import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import {
  getInitialModifyCertificate,
  postProcessModifyCertificate,
} from "@app/products/property/certificates/[id]/components/form-steps/modify-certificate/api";
import { ApplicantDetailFormStep } from "@app/products/property/certificates/[id]/components/form-steps/modify-certificate/components/element-steps/applicant-details/_index";
import { useApplicantDetailStepStore } from "@app/products/property/certificates/[id]/components/form-steps/modify-certificate/components/element-steps/applicant-details/store";
import { CertificateDetailFormStep } from "@app/products/property/certificates/[id]/components/form-steps/modify-certificate/components/element-steps/certificate-details/_index";
import { useCertificateDetailStepStore } from "@app/products/property/certificates/[id]/components/form-steps/modify-certificate/components/element-steps/certificate-details/store";
import { RateDepartmentDetailFormStep } from "@app/products/property/certificates/[id]/components/form-steps/modify-certificate/components/element-steps/rates-department-details/_index";
import { EPrefixField } from "@app/products/property/certificates/[id]/components/form-steps/modify-certificate/components/element-steps/rates-department-details/config";
import { ILoadingProcess } from "@app/products/property/certificates/[id]/components/form-steps/modify-certificate/components/element-steps/rates-department-details/model";
import { useRateDepartmentDetailStepStore } from "@app/products/property/certificates/[id]/components/form-steps/modify-certificate/components/element-steps/rates-department-details/store";
import {
  EModifyCertificateKeysOfSteps,
  keysOfStepsModifyCertificate,
} from "@app/products/property/certificates/[id]/components/form-steps/modify-certificate/config";
import {
  DTO_Certificate_LOVs,
  DTO_Certificate_UDA,
  DTO_LOV_UDAL,
  DTO_Workflow_CertificateModify,
} from "@app/products/property/certificates/[id]/components/form-steps/modify-certificate/model";
import { NamesFormStep } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/components/form-elements/names/_index";
import { QuestionnaireFormStep } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/components/form-elements/responses/_index";
import { DTO_ChecklistQuestion } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/model";
import { useNewCertificateDialogStore } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/store";
import { PROPERTY_CERTIFICATE_ROUTE } from "@app/products/property/certificates/[id]/constant";
import { loadDynamicQuestionList } from "@app/products/property/certificates/[id]/util";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmReallocateDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import {
  IListDialog,
  IProcessWorkflow,
} from "@app/products/property/components/action-bar/property-workflow/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import {
  getTitleWorkflow,
  isApprovalModeWorkflow,
} from "@app/products/property/components/action-bar/property-workflow/util";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
  nameOfLov,
} from "@app/products/property/model";
import {
  convertValueLOVToNumber,
  isShowParkButton,
} from "@app/products/property/util";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { DTO_LOV_Number } from "@common/models/odataResponse";
import { CommunityProperty } from "@common/stores/products/config";
import { useCommonProductStore } from "@common/stores/products/store";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { Button } from "@progress/kendo-react-buttons";
import { addDays } from "date-fns";
import { cloneDeep, head, isNil, isObject, pickBy } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface IModifyCertificateDialog {
  onClose: () => void;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
  prefixTitle?: string;
  suffixTitle?: string;
  reloadPage?: (notification?: IAppNotificationItemAddProps) => void;
}
export const ModifyCertificateDialog = ({
  onClose,
  isSaveOnNextStep = false,
  dataFromActionList,
  prefixTitle,
  suffixTitle,
}: IModifyCertificateDialog) => {
  //#region <Store>
  const { pushNotification } = useCCAppNotificationStore();
  const { setApplicantDetailStepLOVs } = useApplicantDetailStepStore();
  const { setCertificateDetailStepLOVs } = useCertificateDetailStepStore();
  const { setRateDepartmentDetailStepLOVs } =
    useRateDepartmentDetailStepStore();
  //set data for modes
  const { setDataForCancelDialog } = useConfirmCancelDialogStore();
  const { setDataForFinishDialog } = useConfirmFinishDialogStore();
  const { setDataForCloseDialog, setIsLoadingClose } =
    useConfirmCloseDialogStore();
  const { setListDialog } = usePropertyWorkflowStore();
  const { clearSelectedItems } = useCCProductListViewStore();
  const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
  const { setDataForReallocateDialog } = useConfirmReallocateDialogStore();
  const { setDataForRejectDialog } = useConfirmRejectDialogStore();
  const { currentFormTitle } = useCommonProductStore();
  const history = useHistory();
  const { isLLS } = CommunityProperty.getFlagOfStates();
  const {
    isIncompleteMode,
    isFromActionList,
    isReadOnly,
    statusBadge,
    isShowCancelWorkflowButton,
  } = usePropertyWorkflow(dataFromActionList);
  const { setDynamicQuestionList } = useNewCertificateDialogStore();

  //#endregion

  //#region <Local state>
  const { id } = useParams<{ id: string }>();
  const formStepRef = useRef<ICCFormStepNotificationHandle | null>(null);
  const [workflowInitData, setWorkflowInitData] =
    useState<DTO_Workflow_CertificateModify>();

  const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
    WorkflowDraft: { Workflow_Draft_Id: 0 },
    AvailableSecondaryWorkflows: [],
    WorkflowApprovals: [],
  });
  const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
  const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
  const [isLoadingProcess, setIsLoadingProcess] = useState<
    WorkflowProcessMode | undefined | ILoadingProcess
  >();
  const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
  const [isLoadingApprove, setIsLoadingApprove] = useState<boolean>(false);
  const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
  const [isLoadingInStep, setIsLoadingInStep] = useState<boolean>(false);
  const [isResponsesStepVisible, setIsResponsesStepVisible] =
    useState<boolean>(false);
  //#endregion

  //#region <Title header>
  const titleHeader = useMemo(() => {
    const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
    const title = currentFormTitle(formId ?? 0) || "Modify Certificate";
    return getTitleWorkflow(title, prefixTitle, suffixTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefixTitle, suffixTitle, workflowHeader]);
  //#endregion

  const isApprovalMode = useMemo(() => {
    return isApprovalModeWorkflow(
      workflowHeader?.WorkflowDraft?.WD_Workflow_Approval_Status
    );
  }, [workflowHeader]);

  const dynamicQuestionListID =
    workflowInitData?.WorkflowDetail?.Questionnaire?.DynamicQuestionList_ID ??
    0;

  //region <Initial value>
  const initialValue = useMemo((): any => {
    //applicant step
    let initApplicantDetails: any = {
      ...workflowInitData?.WorkflowDetail?.Applicant,
      Name: {
        Name: workflowInitData?.WorkflowDetail?.Applicant?.Name,
      },
      _option: {
        Locality: {
          Locality_Code: workflowInitData?.WorkflowDetail?.Applicant?.Locality,
        },
      },
    };
    //certificate detail step
    const originatedOn =
      workflowInitData?.WorkflowDetail?.CertificateDetails?.OriginatedOn;
    //calculate Excepted Completion base on ExpectedCompletionDays
    let exceptedCompletion;
    if (!isNil(originatedOn)) {
      let expectedCompletionDays =
        workflowInitData?.WorkflowDetail?.CertificateDetails
          ?.ExpectedCompletionDays ?? 0;
      exceptedCompletion = addDays(originatedOn, expectedCompletionDays);
    }
    let initCertificateDetails: any = {
      ...workflowInitData?.WorkflowDetail?.CertificateDetails,
      _option: {
        ExceptedCompletion: exceptedCompletion,
      },
    };
    //rate department detail step
    const dataUDAL = workflowInitData?.WorkflowDetail?.RateDetail?.UDAL ?? [];
    const fieldsUDA: any = {};
    dataUDAL?.forEach((item: DTO_Certificate_UDA) => {
      fieldsUDA[`${EPrefixField.Comboboxes}_${item.UDA_Attribute_Id}`] =
        item?.UDA_Code;
      fieldsUDA[`${EPrefixField.TextArea}_${item.UDA_Attribute_Id}`] =
        item?.UDA_Text;
    });
    let initRateDepartmentDetails: any = {
      ...workflowInitData?.WorkflowDetail?.RateDetail,
      _option: {
        ...fieldsUDA,
      },
    };
    let initNames: any = {
      ProposedSettlementDate:
        workflowInitData?.WorkflowDetail?.ProposedSettlementDate,
      Associated_Entities: workflowInitData?.WorkflowDetail?.Contact?.Contacts,
    };
    let initQuestionnaire: any = {
      DynamicQuestionList_ID: dynamicQuestionListID,
      Questions: workflowInitData?.WorkflowDetail?.Questionnaire?.Questions,
      Answers: workflowInitData?.WorkflowDetail?.Answers,
    };

    // Show/hide 'Responses' steps based on dynamic question list
    formStepRef?.current?.setStepsVisible([
      {
        visible: isResponsesStepVisible,
        key: EModifyCertificateKeysOfSteps.Questionnaire,
        isClearData: false,
      },
    ]);

    return {
      [EModifyCertificateKeysOfSteps.ApplicantDetails]: initApplicantDetails,
      [EModifyCertificateKeysOfSteps.CertificateDetails]:
        initCertificateDetails,
      [EModifyCertificateKeysOfSteps.RateDepartmentDetails]:
        initRateDepartmentDetails,
      [EModifyCertificateKeysOfSteps.Names]: initNames,
      [EModifyCertificateKeysOfSteps.Questionnaire]: initQuestionnaire,
      [EModifyCertificateKeysOfSteps.Documents]: {},
      [EModifyCertificateKeysOfSteps.Comments]: {},
    };
    // eslint-disable-next-line
  }, [workflowInitData, isResponsesStepVisible]);
  //#endregion

  const steps: IStep[] = [
    {
      label: "Applicant details",
      initialValues:
        initialValue[EModifyCertificateKeysOfSteps.ApplicantDetails],
      component: ApplicantDetailFormStep,
      visible: true,
      key: EModifyCertificateKeysOfSteps.ApplicantDetails,
      options: {
        isReadOnly,
        setIsLoadingProcess,
        setIsLoadingInStep,
        isLoadingInStep,
      },
    },
    {
      label: "Certificate details",
      initialValues:
        initialValue[EModifyCertificateKeysOfSteps.CertificateDetails],
      component: CertificateDetailFormStep,
      visible: true,
      key: EModifyCertificateKeysOfSteps.CertificateDetails,
      options: {
        isReadOnly,
      },
    },
    {
      label: "Rates department details",
      initialValues:
        initialValue[EModifyCertificateKeysOfSteps.RateDepartmentDetails],
      component: RateDepartmentDetailFormStep,
      visible: !isLLS,
      key: EModifyCertificateKeysOfSteps.RateDepartmentDetails,
      options: {
        isReadOnly,
      },
    },
    {
      label: "Names",
      initialValues: initialValue[EModifyCertificateKeysOfSteps.Names],
      component: NamesFormStep,
      visible: true,
      key: EModifyCertificateKeysOfSteps.Names,
      options: {
        isReadOnly,
      },
    },
    {
      label: "Responses",
      initialValues: initialValue[EModifyCertificateKeysOfSteps.Questionnaire],
      component: QuestionnaireFormStep,
      visible: false,
      key: EModifyCertificateKeysOfSteps.Questionnaire,
      options: {
        isReadOnly,
        id: dynamicQuestionListID,
      },
    },
    {
      label: "Comments",
      component: CommentsFormStep,
      visible: true,
      key: EModifyCertificateKeysOfSteps.Comments,
      customClassName: "cc-comment-step-fixed-height-grid",
      options: {
        isReadOnly,
        workflowDraftId,
        recordType: RECORDTYPE.CommunityProperty_Certificate,
      },
      initialValues: initialValue[EModifyCertificateKeysOfSteps.Comments],
    },
    {
      label: "Documents",
      component: DocumentsFormStep,
      visible: true,
      key: EModifyCertificateKeysOfSteps.Documents,
      options: {
        isReadOnly,
        workflowDraftId,
        workflowType: WorkflowTypes.Certificate_Modify,
      },
      initialValues: initialValue[EModifyCertificateKeysOfSteps.Documents],
    },
  ];

  //#region handle Cancel Button>
  const handleCancelButton = (data: any) => {
    if (isFromActionList || !isFirstSave) {
      setDataForCancelDialog({
        cancelAPI: postProcessModifyCertificate,
        dataCancel: data,
        defaultSuccessMessage: "Modify certificate was cancelled successfully.",
        defaultErrorMessage: "Modify certificate could not be cancelled.",
      });
    } else {
      onClose();
    }
  };
  //#endregion

  //#region <Handle close dialog>
  /**
   * @param renderProps
   */
  const handleCloseDialog = (renderProps: ICCFormStepRender) => {
    if (!isFromActionList && !isFirstSave) {
      //Store submit event
      setDataForCloseDialog({
        closeCallback: renderProps.submitButton.onClick,
      });
    } else if (
      isIncompleteMode &&
      dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
    ) {
      onClose();
    } else if (
      dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Incomplete &&
      !isFirstSave
    ) {
      const newEvent = {
        currentTarget: { id: EListSubmitButton.Close },
      };
      renderProps.submitButton.onClick(newEvent);
    } else {
      onClose();
    }
  };
  //#endregion

  const handleSaveAndNext = async (
    payload: DTO_Workflow_CertificateModify,
    isCloseDialog: boolean = false,
    isRefreshWorkflowData: boolean = false
  ): Promise<boolean> => {
    //Calling process Save at next button
    const response = await postProcessModifyCertificate(
      WorkflowProcessMode.Save,
      payload
    );

    setIsLoadingOnNext(false);

    //set default notification
    const defaultSuccessMessage = "Modify certificate was saved successfully.";
    const defaultFailedMessage = "Modify certificate could not be saved.";

    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      if (isCloseDialog) {
        onClose();
        pushNotification({
          title: response?.data?.Notification ?? defaultSuccessMessage,
          type: "success",
        });
      }
      // check is the first saving
      if (isFirstSave) {
        setIsFirstSave(false);
        //set current workflowDraft Id
        setWorkflowDraftId(response?.data?.ID || 0);
        // set payload to send
        setWorkflowHeader({
          ...workflowHeader,
          WorkflowDraft: {
            ...workflowHeader.WorkflowDraft,
            Workflow_Draft_Id: response?.data?.ID || workflowDraftId || 0,
          },
        });
      }
      return true;
    } else {
      formStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.pushNotification({
          title:
            (isRefreshWorkflowData
              ? head(response?.data?.Errors)
              : response.data?.ErrorMessage) ?? defaultFailedMessage,
          type: "error",
          autoClose: false,
        });
      return false;
    }
  };

  const handleNextButton = async (data: DTO_Workflow_CertificateModify) => {
    setIsLoadingOnNext(true);
    const newData = processData(data);
    return handleSaveAndNext(newData);
  };

  /**
   * @param payload
   */
  const handleParkProcess = async (payload: DTO_Workflow_CertificateModify) => {
    //set loading button and dialog
    setIsLoadingPark(true);

    //props send to process workflow
    const parkProps: IProcessWorkflow<DTO_Workflow_CertificateModify> = {
      payload: payload,
      actionSuccess: (e) => {
        onClose();
        pushNotification({
          title:
            e?.Notification ??
            e?.SuccessMessage ??
            "Modify certificate was parked successfully.",
          type: "success",
        });
      },
      defaultFailedMessage: "Modify certificate could not be parked.",
      modeProcess: WorkflowProcessMode.Park,
    };

    const setLoading = () => setIsLoadingPark(false);

    //calling api process workflow
    await handleProcessWorkflow(parkProps, setLoading);
  };

  //#region
  const handleSubmit = async (data: any, buttonId?: string) => {
    switch (buttonId) {
      case EListSubmitButton.Finish:
        handleConfirmFinish(data);
        break;
      case EListSubmitButton.Cancel:
      case EListSubmitButton.ConfirmCloseNo:
        handleCancelButton(processData(data));
        break;
      case EListSubmitButton.ConfirmCloseYes:
        await handleConfirmRetainProcess(processData(data));
        break;
      case EListSubmitButton.Park:
      case EListSubmitButton.Close:
        await handleParkProcess(processData(data));
        break;
      case EListSubmitButton.Approve:
        await handleApproveProcess(processData(data));
        break;
      case EListSubmitButton.Save:
        await handleSaveAndNext(processData(data), true);
        break;
      case EListSubmitButton.Reject:
        handleRejectButton(processData(data));
        break;
      case EListSubmitButton.SendBack:
        handleConfirmSendBackProcess(processData(data));
        break;
      case EListSubmitButton.Reallocate:
        handleConfirmReallocateProcess(processData(data));
        break;
    }
  };
  //#endregion

  /**
   * Handle reject process
   */
  const handleRejectButton = (data: any) => {
    if (isFromActionList || !isFirstSave) {
      setDataForRejectDialog({
        rejectCallback: postProcessModifyCertificate,
        dataReject: data,
        defaultSuccessMessage: `Modify certificate application was rejected successfully`,
        defaultErrorMessage: `Modify certificate reject failed`,
      });
    } else {
      onClose();
    }
  };

  /**
   * handle confirm send back workflow process
   * @param payload
   */
  const handleConfirmSendBackProcess = (
    payload: DTO_Workflow_CertificateModify
  ) => {
    setDataForSendBackDialog({
      sendBackCallback: postProcessModifyCertificate,
      dataSendBack: payload,
      defaultSuccessMessage: `Modify certificate approval sent back successfully`,
      defaultErrorMessage: `Modify certificate back failed`,
    });
  };

  /**
   * handle confirm reallocate workflow process
   * @param payload
   */
  const handleConfirmReallocateProcess = (
    payload: DTO_Workflow_CertificateModify
  ) => {
    setDataForReallocateDialog({
      reallocateCallback: postProcessModifyCertificate,
      dataReallocate: payload,
      defaultSuccessMessage: `Modify certificate approved successfully`,
      defaultErrorMessage: `Approve modify certificate failed`,
    });
  };

  /**
   * handle approve process
   */
  const handleApproveProcess = async (
    payload: DTO_Workflow_CertificateModify
  ) => {
    //set loading button
    setIsLoadingApprove(true);

    //props send to process workflow
    const approveProps: IProcessWorkflow<DTO_Workflow_CertificateModify> = {
      payload: payload,
      actionSuccess: (e) => {
        onClose();
        clearSelectedItems();
        eventEmitter.emit(CCGridEventType.RefreshOData);
        pushNotification({
          title:
            e?.Notification ?? `Modify certificate was approved successfully`,
          type: "success",
        });
      },
      defaultFailedMessage: `Approve modify certificate was failed`,
      modeProcess: WorkflowProcessMode.Approve,
    };

    const setLoading = () => {
      setIsLoadingApprove(false);
    };
    //calling api process workflow
    await handleProcessWorkflow(approveProps, setLoading);
  };

  /**
   * handle confirm retain workflow process
   * @param payload
   */
  const handleConfirmRetainProcess = async (
    payload: DTO_Workflow_CertificateModify
  ) => {
    //set loading button and dialog
    setIsLoadingClose(true);

    //props send to process workflow
    const parkProps: IProcessWorkflow<DTO_Workflow_CertificateModify> = {
      payload: payload,
      actionSuccess: (e) => {
        onClose();
        pushNotification({
          title:
            e?.Notification ??
            e?.SuccessMessages ??
            "Modify certificate was parked successfully.",
          type: "success",
        });
      },
      defaultFailedMessage: "Modify certificate could not be parked.",
      modeProcess: WorkflowProcessMode.Park,
    };

    const setLoading = () => {
      setIsLoadingClose(false);
      setDataForCloseDialog(undefined);
    };

    //calling api process workflow
    await handleProcessWorkflow(parkProps, setLoading);
  };

  const processData = (data: any) => {
    let workflowDetail: any = {};
    const newData = { ...data };
    const sendSteps = pickBy(newData, (value, key) => {
      if (
        keysOfStepsModifyCertificate.includes(
          key as EModifyCertificateKeysOfSteps
        )
      ) {
        return { [key]: value };
      }
    });
    const udaValue: any = cloneDeep(
      workflowInitData?.WorkflowDetail?.RateDetail?.UDAL
    );
    let udaAfterProcess = udaValue;
    const dataUDA =
      newData[`${EModifyCertificateKeysOfSteps.RateDepartmentDetails}`]._option;
    if (dataUDA) {
      udaAfterProcess = udaAfterProcess?.map((item: DTO_LOV_UDAL) => {
        const udaId = item.UDA_Attribute_Id;
        return {
          ...item,
          UDA_Code: dataUDA?.[`${EPrefixField.Comboboxes}_${udaId}`],
          UDA_Text: dataUDA?.[`${EPrefixField.TextArea}_${udaId}`],
        };
      });
    }
    sendSteps[`${EModifyCertificateKeysOfSteps.RateDepartmentDetails}`].UDAL =
      udaAfterProcess;
    for (const [key, value] of Object.entries(sendSteps)) {
      const dataStep = { ...value };
      if (dataStep && dataStep?._option) {
        delete dataStep._option;
      }
      workflowDetail[key] = dataStep;
      workflowDetail = {
        ...workflowDetail,
        Answers: workflowInitData?.WorkflowDetail?.Answers,
        CertificateId: workflowInitData?.WorkflowDetail?.CertificateId,
        CertificateType: workflowInitData?.WorkflowDetail?.CertificateType,
        ProposedSettlementDate:
          workflowInitData?.WorkflowDetail?.ProposedSettlementDate,
      };
      workflowDetail.Applicant.Name = isObject(data?.Applicant?.Name)
        ? data?.Applicant?.Name?.Name
        : data?.Applicant?.Name;
      if (key === EModifyCertificateKeysOfSteps.Questionnaire) {
        workflowDetail.Contact = {
          ...workflowDetail.Contact,
          Contacts: data?.Names?.Associated_Entities,
        };
        delete workflowDetail.Names;
        delete workflowDetail.Questionnaire.Answers;
      }
    }

    return {
      WorkflowHeader: workflowHeader,
      WorkflowDetail: workflowDetail,
    };
  };

  /**
   * common function
   * handle calling api with multiple process
   * @param props
   */
  const handleProcessWorkflow = async (
    props: IProcessWorkflow<DTO_Workflow_CertificateModify>,
    setLoading: () => void
  ) => {
    const { payload, actionSuccess, defaultFailedMessage, modeProcess } = props;
    let secondaryWorkflowSelectedList: IListDialog[] = [];
    const response = await postProcessModifyCertificate(modeProcess, payload);

    setLoading();
    if (isSuccessResponse(response)) {
      if (response?.data?.IsSuccess) {
        actionSuccess(response?.data);
        //Show secondary workflow
        if (
          modeProcess === WorkflowProcessMode.Finish &&
          secondaryWorkflowSelectedList?.length
        ) {
          setListDialog(secondaryWorkflowSelectedList);
        }
      } else {
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    } else {
      formStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.pushNotification({
          title: response?.data?.ErrorMessage ?? defaultFailedMessage,
          type: "error",
          autoClose: false,
        });
    }
  };

  /**
   * handle finish process
   * @param payload
   */
  const handleFinishProcess = async (
    payload: DTO_Workflow_CertificateModify
  ) => {
    //props send to process workflow
    const finishProps: IProcessWorkflow<DTO_Workflow_CertificateModify> = {
      payload: payload,
      actionSuccess: (e) => {
        clearSelectedItems();
        const notificationContent: IAppNotificationItemAddProps = {
          title:
            e?.Notification ?? "Modify certificate was created successfully.",
          type: "success",
        };
        onClose();
        if (
          workflowInitData?.WorkflowHeader?.WorkflowApprovals?.length === 0 &&
          !isNil(e?.Component_ID)
        ) {
          history.push(`${PROPERTY_CERTIFICATE_ROUTE}/${e?.Component_ID}`, {
            notification: notificationContent,
          });
        } else {
          pushNotification(notificationContent);
        }
      },
      defaultFailedMessage: "Modify certificate could not be created.",
      modeProcess: WorkflowProcessMode.Finish,
    };

    const setLoading = () => {};
    //calling api process workflow
    await handleProcessWorkflow(finishProps, setLoading);
  };

  //#region <Handle confirm finish>
  const handleConfirmFinish = (payload: DTO_Workflow_CertificateModify) => {
    const dataProcessed = processData(payload);
    const finishCallback = function async() {
      return handleFinishProcess(dataProcessed);
    };
    setDataForFinishDialog({
      finishCallback,
      confirmMessage:
        "The modify certificate will be updated based on the information provided. Are you sure you want to submit?",
    });
  };
  //#endregion

  //#region <Get workflow data>
  /**
   * load initValue for FormStep
   * call once time
   */
  const getWorkflowData = async () => {
    const workflowDraftId = dataFromActionList?.Workflow_Draft_Id;
    formStepRef?.current?.setLoadingFormStep(true);
    const response = await getInitialModifyCertificate(
      WorkflowTypes.Certificate_Modify,
      workflowDraftId,
      isFromActionList,
      isFromActionList ? undefined : +id
    );
    if (Array.isArray(response)) {
      const [workflowLOVs, workflowData] = response;
      if (
        isSuccessResponse(workflowLOVs) &&
        isSuccessResponse(workflowData) &&
        workflowLOVs?.data &&
        workflowData?.data
      ) {
        //set Lovs Applicant Detail Step
        setApplicantDetailStepLOVs({
          Applicants: workflowLOVs.data.Applicants,
          States: workflowLOVs.data.States,
        });
        //set Lovs Certificate Detail Step
        setCertificateDetailStepLOVs({
          RequestSource: convertValueLOVToNumber<DTO_LOV_Number>(
            workflowLOVs?.data?.RequestSource ?? [],
            nameOfLov("Code")
          ),
        });
        //process group by UDA_Attribute_Id UDA LOV
        let udaDataLovs: any = cloneDeep(workflowLOVs?.data?.UDAL);
        udaDataLovs = udaDataLovs?.reduce((group: any, item: DTO_LOV_UDAL) => {
          const { UDA_Attribute_Id } = item;
          group[UDA_Attribute_Id] = group[UDA_Attribute_Id] || [];
          group[UDA_Attribute_Id].push(item);
          return group;
        }, {});
        // process UDA dynamic fields
        let udas = convertValueLOVToNumber<DTO_LOV_Number>(
          workflowLOVs?.data?.UDAs ?? [],
          nameOfLov("Code")
        );
        const dataUDA =
          workflowData?.data?.WorkflowDetail?.RateDetail?.UDAL ?? [];
        udas = udas.map((item) => {
          const itemUDA = dataUDA?.find(
            (uda) => item.Code === uda.UDA_Attribute_Id
          );
          return itemUDA ? { ...item, UDA_Label: itemUDA["UDA_Label"] } : item;
        });
        //set Lovs Rate Department Detail Step
        setRateDepartmentDetailStepLOVs({
          UDAs: udas,
          UDALOvs: udaDataLovs,
          UDAL: workflowLOVs?.data?.UDAL,
        });
        setWorkflowInitData(workflowData?.data);
        if (workflowData.data?.WorkflowHeader) {
          setWorkflowHeader(workflowData.data?.WorkflowHeader);
          setWorkflowDraftId(
            workflowData.data?.WorkflowHeader?.WorkflowDraft
              ?.Workflow_Draft_Id ?? 0
          );
        }

        // Load dynamic question list to show/hide Responses step --->
        // Get dynamic question list ID of the selected certificate type
        const dynamicQuestionListId =
          workflowData?.data?.WorkflowDetail?.Questionnaire
            ?.DynamicQuestionList_ID;

        // Call API to load dynamic question list by dynamicQuestionListId
        await loadDynamicQuestionList({
          dynamicQuestionListId,
          onTurnOffLoading: () => {
            formStepRef?.current?.setLoadingFormStep(false);
          },
          onSuccess: (data: DTO_ChecklistQuestion[]) => {
            setDynamicQuestionList(data);
            setIsResponsesStepVisible(data?.length > 0);
          },
          onError: (error: APIResponseError) => {
            formStepRef?.current?.setLoadFailedFormStep({
              onReload: () => getWorkflowData(),
              responseError: {
                status: error.status,
                error: error.error ?? "Load workflow failed",
              },
            });
            return;
          },
        });

        // Load dynamic question list to handle show/hide Responses step <---
        formStepRef?.current?.setLoadingFormStep(false);
      } else {
        let responseError: APIResponse<
          DTO_Certificate_LOVs | DTO_Workflow_CertificateModify | ResponsePacket
        > = response[0];
        if (!isSuccessResponse(response[1])) {
          responseError = response[1];
        }
        formStepRef?.current?.setLoadingFormStep(false);
        formStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error:
              (responseError.data as ResponsePacket)?.Errors ??
              "Load workflow failed",
          },
        });
      }
    } else {
      const responseError = response as APIResponse;
      formStepRef?.current?.setLoadFailedFormStep({
        onReload: () => getWorkflowData(),
        responseError: {
          status: responseError.status,
          error: "Load workflow failed",
        },
      });
    }
  };
  //#endregion

  //#region <Use Effect calling initial data>
  useEffectOnce(() => {
    getWorkflowData();
  });
  //#endregion

  return (
    <CCFormStep
      ref={formStepRef}
      onSubmit={handleSubmit}
      initialSteps={steps}
      initialValues={initialValue}
      listButtonId={listSubmitButton}
      saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
      renderForm={(renderProps: ICCFormStepRender) => (
        <CCDialog
          maxWidth="60%"
          titleHeader={titleHeader}
          onClose={() => handleCloseDialog(renderProps)}
          bodyElement={renderProps.children}
          badge={statusBadge}
          disabled={isLoadingProcess !== undefined || isLoadingInStep}
          footerElement={
            <>
              <div className={"cc-dialog-footer-actions-right"}>
                {isShowParkButton(isFromActionList, isIncompleteMode) && (
                  <Button
                    iconClass={isLoadingPark ? "fas fa-spinner fa-spin" : ""}
                    className={"cc-dialog-button"}
                    id={EListSubmitButton.Park}
                    onClick={renderProps.submitButton.onClick}
                    disabled={renderProps.nextButton.disabled || isLoadingPark}
                  >
                    Park
                  </Button>
                )}
                {isShowCancelWorkflowButton && (
                  <Button
                    className={"cc-dialog-button"}
                    disabled={
                      isLoadingApprove || isLoadingOnNext || isLoadingPark
                    }
                    id={EListSubmitButton.Cancel}
                    onClick={renderProps.submitButton.onClick}
                  >
                    Cancel
                  </Button>
                )}

                {isApprovalMode && workflowHeader?.OfficerCanApprove && (
                  <>
                    <Button
                      themeColor="primary"
                      id={EListSubmitButton.SendBack}
                      disabled={renderProps.nextButton.disabled}
                      className={"cc-dialog-button"}
                      onClick={renderProps.submitButton.onClick}
                    >
                      Send Back
                    </Button>
                    <Button
                      themeColor="primary"
                      id={EListSubmitButton.Reallocate}
                      disabled={renderProps.nextButton.disabled}
                      className={"cc-dialog-button"}
                      onClick={renderProps.submitButton.onClick}
                    >
                      Reallocate
                    </Button>
                    <Button
                      themeColor="primary"
                      id={EListSubmitButton.Approve}
                      disabled={
                        isLoadingApprove || renderProps.nextButton.disabled
                      }
                      className={"cc-dialog-button"}
                      onClick={renderProps.submitButton.onClick}
                      iconClass={
                        isLoadingApprove ? "fas fa-spinner fa-spin" : ""
                      }
                    >
                      Approve
                    </Button>
                    <Button
                      themeColor="primary"
                      id={EListSubmitButton.Reject}
                      disabled={renderProps.nextButton.disabled}
                      className={"cc-dialog-button"}
                      onClick={renderProps.submitButton.onClick}
                    >
                      Reject
                    </Button>
                  </>
                )}
                {!renderProps.prevButton.disabled && (
                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={renderProps.prevButton.onClick}
                  >
                    Previous
                  </Button>
                )}
                {isApprovalMode || isReadOnly ? (
                  !renderProps.isLastStep && (
                    <Button
                      themeColor="primary"
                      id="cc-next-step-button"
                      disabled={
                        isLoadingOnNext || renderProps.nextButton.disabled
                      }
                      className={"cc-dialog-button"}
                      iconClass={
                        isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                      }
                      onClick={renderProps.nextButton.onClick}
                    >
                      {isLoadingOnNext
                        ? "Saving"
                        : renderProps.nextButton.label}
                    </Button>
                  )
                ) : (
                  <Button
                    themeColor="primary"
                    id={renderProps.nextButton.idButton}
                    disabled={
                      isLoadingOnNext || renderProps.nextButton.disabled
                    }
                    iconClass={isLoadingOnNext ? "fas fa-spinner fa-spin" : ""}
                    className={"cc-dialog-button"}
                    onClick={renderProps.nextButton.onClick}
                  >
                    {isLoadingOnNext ? "Saving" : renderProps.nextButton.label}
                  </Button>
                )}
              </div>
            </>
          }
        />
      )}
    />
  );
};
