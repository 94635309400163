import { AddCommentPropertyButton } from "@app/core/comments/components/buttons/add-comment/components/add-comment-property/_index";
import { PropertyComments } from "@app/core/comments/components/property-comments/_index";
import { MailMergeButtonManagePage } from "@app/core/new-mail-merge/buttons/mail-merge-manage-page/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { AmountOwing } from "@app/products/property/assessments/[id]/components/child-screens/amount-owing/_index";
import { AnnualStock } from "@app/products/property/assessments/[id]/components/child-screens/annual-returns/_index";
import { Arrangement } from "@app/products/property/assessments/[id]/components/child-screens/arrangement/_index";
import { AssociateAssessmentAccordion } from "@app/products/property/assessments/[id]/components/child-screens/associate-assessment/_index";
import { AssessmentAttributesUDA } from "@app/products/property/assessments/[id]/components/child-screens/attributes-uda/_index";
import { Certificates } from "@app/products/property/assessments/[id]/components/child-screens/certificates/_index";
import { Charges } from "@app/products/property/assessments/[id]/components/child-screens/charges/_index";
import { useAssessmentChargesStore } from "@app/products/property/assessments/[id]/components/child-screens/charges/store";
import { Compliance } from "@app/products/property/assessments/[id]/components/child-screens/compliance/_index";
import { Contacts } from "@app/products/property/assessments/[id]/components/child-screens/contacts/_index";
import { AssessmentDebtRecovery } from "@app/products/property/assessments/[id]/components/child-screens/debt-recovery/_index";
import { Deferments } from "@app/products/property/assessments/[id]/components/child-screens/deferments/_index";
import { PropertyAssessmentForm } from "@app/products/property/assessments/[id]/components/child-screens/general/_index";
import { Interest } from "@app/products/property/assessments/[id]/components/child-screens/interest/_index";
import { LandRent } from "@app/products/property/assessments/[id]/components/child-screens/land-rent/_index";
import { LevyBalances } from "@app/products/property/assessments/[id]/components/child-screens/levy-balances/_index";
import { Meters } from "@app/products/property/assessments/[id]/components/child-screens/meters/_index";
import { useAssessmentMeterStore } from "@app/products/property/assessments/[id]/components/child-screens/meters/store";
import { Notices } from "@app/products/property/assessments/[id]/components/child-screens/notices/_index";
import { Parcels } from "@app/products/property/assessments/[id]/components/child-screens/parcels/_index";
import RateBreakup from "@app/products/property/assessments/[id]/components/child-screens/rate-breakup/_index";
import { Rating } from "@app/products/property/assessments/[id]/components/child-screens/rating/_index";
import { Rebates } from "@app/products/property/assessments/[id]/components/child-screens/rebates/_index";
import Registers from "@app/products/property/assessments/[id]/components/child-screens/registers/_index";
import { Transactions } from "@app/products/property/assessments/[id]/components/child-screens/transactions/_index";
import { Transfer } from "@app/products/property/assessments/[id]/components/child-screens/transfer/_index";
import { Valuations } from "@app/products/property/assessments/[id]/components/child-screens/valuations/_index";
import { AdjustChargeButton } from "@app/products/property/assessments/[id]/components/forms/existed/components/action-bar/buttons/adjust -charge/_index";
import { AdjustChargeBalancesButton } from "@app/products/property/assessments/[id]/components/forms/existed/components/action-bar/buttons/adjust-charge-balances/_index";
import { CancelOwnerChangeButton } from "@app/products/property/assessments/[id]/components/forms/existed/components/action-bar/buttons/cancel_owner-change/_index";
import { CreateDefermentAssessmentButton } from "@app/products/property/assessments/[id]/components/forms/existed/components/action-bar/buttons/create-deferment/_index";
import { EditChangeOfOwnershipAssessmentButton } from "@app/products/property/assessments/[id]/components/forms/existed/components/action-bar/buttons/edit-change-of-ownership/_index";
import { EnterSpecialReadingButton } from "@app/products/property/assessments/[id]/components/forms/existed/components/action-bar/buttons/enter-special-reading/_index";
import { NewAssessmentTransactionButton } from "@app/products/property/assessments/[id]/components/forms/existed/components/action-bar/buttons/new-transaction/_index";
import { RaiseChargeButton } from "@app/products/property/assessments/[id]/components/forms/existed/components/action-bar/buttons/raise-charge/_index";
import { AddRebateButton } from "@app/products/property/assessments/[id]/components/forms/existed/components/action-bar/buttons/rebates/add-rebate/_index";
import { UpdateLossOfEligibilityDateButton } from "@app/products/property/assessments/[id]/components/forms/existed/components/action-bar/buttons/rebates/update-loss-of-eligibility-date/_index";
import { ReverseTransactionButton } from "@app/products/property/assessments/[id]/components/forms/existed/components/action-bar/buttons/reverse-transaction/_index";
import { TransferButton } from "@app/products/property/assessments/[id]/components/forms/existed/components/action-bar/buttons/transfer-meter/_index";
import { TransferTransactionButton } from "@app/products/property/assessments/[id]/components/forms/existed/components/action-bar/buttons/transfer-transaction/_index";
import { AddToNoticeGroupButton } from "@app/products/property/assessments/[id]/components/forms/existed/components/action-bar/buttons/update-notice-group/_index";
import { AssessmentsAssociationsTab } from "@app/products/property/assessments/[id]/components/reference-sidebar/associations/_index";
import { AssessmentDetailTab } from "@app/products/property/assessments/[id]/components/reference-sidebar/detail/_index";
import { AssessmentMapTab } from "@app/products/property/assessments/[id]/components/reference-sidebar/map/_index";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { LandTaxExemptionButton } from "@app/products/property/assessments/compliance/candidates/components/action-bar/buttons/land-tax-exemption/_index";
import { AmalgamateAssessmentManagePageButton } from "@app/products/property/assessments/components/action-bar/buttons/amalgamate-assessment/components/manage-page/_index";
import { AssessmentLandRentActionButton } from "@app/products/property/assessments/components/action-bar/buttons/assessment-land-rent-action/_index";
import { AssociateAssessmentButton } from "@app/products/property/assessments/components/action-bar/buttons/associate-assessment/_index";
import { ChangeOfOwnerShipButton } from "@app/products/property/assessments/components/action-bar/buttons/change-of-ownership/_index";
import { DeleteDeferment } from "@app/products/property/assessments/components/action-bar/buttons/delete-deferment/_index";
import { DisAssociateAssessmentButton } from "@app/products/property/assessments/components/action-bar/buttons/disassociate-assessment/_index";
import { ModifyAssessmentButton } from "@app/products/property/assessments/components/action-bar/buttons/modify-assessment/_index";
import { ModifyChargeDetailsButton } from "@app/products/property/assessments/components/action-bar/buttons/modify-charge-details/_index";
import { RaiseAssessmentInterestButton } from "@app/products/property/assessments/components/action-bar/buttons/raise-assessment-interest/_index";
import { ReactivateAssessmentButton } from "@app/products/property/assessments/components/action-bar/buttons/reactivate-assessment/_index";
import { SplitAssessmentButton } from "@app/products/property/assessments/components/action-bar/buttons/split-assessment/_index";
import { SubdivideConsolidateButton } from "@app/products/property/assessments/components/action-bar/buttons/subdivide-consolidate/_index";
import { ASSESSMENT_ROUTE } from "@app/products/property/assessments/components/form-steps/modify-assessment/util";
import { CreateDebtRecoveryExemptionButton } from "@app/products/property/assessments/debt-recovery/candidates/components/action-bar/buttons/create-debt-recovery-exemption/_index";
import { AddToSupplementaryButton } from "@app/products/property/assessments/master-properties/components/actionbar/buttons/add-to-supplementary/_index";
import { AddAttachmentPropertyButton } from "@app/products/property/components/action-bar/button/add-attachment/_index";
import { eDocumentTypeCategory } from "@app/products/property/components/action-bar/button/add-attachment/model";
import { CollectionsButton } from "@app/products/property/components/action-bar/button/collections/_index";
import { NewDebtRecoveryButton } from "@app/products/property/components/action-bar/button/new-debt-recovery/_index";
import { PropertyAssociations } from "@app/products/property/components/associations/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyActions } from "@app/products/property/components/child-screen/actions";
import { PropertyCollections } from "@app/products/property/components/child-screen/collections/_index";
import { PropertyDocuments } from "@app/products/property/components/child-screen/documents/_index";
import { SAPTransaction } from "@app/products/property/components/child-screen/sap-transactions/_index";
import { GISAccordion } from "@app/products/property/components/gis-accordion/_index";
import { LineageAccordion } from "@app/products/property/components/lineage-accordion/_index";
import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { PropertyContactTab } from "@app/products/property/components/reference-sidebar/contact/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { ECustomColNameProperty } from "@app/products/property/config";
import { CreateDeferredDutyButton } from "@app/products/property/deferred-duty/components/action-bar/buttons/create-deferred-duty/_index";
import { RemoveMeterButton } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/buttons/remove-meter/_index";
import { ReplaceMeterButton } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/buttons/replace-meter/_index";
import { IMeterFormGeneral } from "@app/products/property/meters/[id]/model";
import { ScheduleSpecialReadingsButton } from "@app/products/property/meters/list/components/action-bar/buttons/schedule-special-readings/_index";
import { CreatePICButton } from "@app/products/property/pic/list/components/action-bar/buttons/new-pic/_index";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { CommunityProperty, Label } from "@common/stores/products/config";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { eAccordionType } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import { default as React, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

export const ExistedPropertyAssessment = observer(() => {
  const {
    assessment,
    isLoading,
    responseLoadError,
    loadAssessment,
    assessmentId,
  } = useAssessmentStore();

  const { pushNotification } = useCCAppNotificationStore();

  const [showSlideBar, setShowSlideBar] = useState(true);

  const { meterSelected } = useAssessmentMeterStore();
  const { chargesSelected } = useAssessmentChargesStore();
  const associationsRef = useRef<any>(null);

  const location = useLocation<any>();
  const scrollToViewRef = useRef<any>(null);
  const isExpandedTransfer = !!location.state?.isExpandedTransfer;
  const isExpandedRating = !!location.state?.isExpandedRating;
  const isExpandedRebate = !!location.state?.isExpandedRebate;
  const isExpandedDeferment = !!location.state?.isExpandedDeferment;

  const { id } = useParams<{ id: string }>();
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();
  const { isLLS, isActro, isGeneral } = CommunityProperty.getFlagOfStates();

  //Get label
  const assessmentLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.Assessment
  );

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        assessment?.Assessment.AssessmentNumber,
        assessment?.Assessment.PropertyName_Address_Locality,
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `Property - ${assessmentLabel} - ${assessmentId ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CommunityProperty,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_Unknown,
      Record_ID: assessmentId ?? 0,
    },
  ];

  const getTitleHeader = useMemo(() => {
    const isUseAssessRefAsPrimary =
      assessment?.Settings?.SettingFields &&
      assessment?.Settings?.SettingFields.find(
        (settingField) => settingField.FieldName === "UseAssessRefAsPrimary"
      )?.Value;
    const assessmentNumber = isUseAssessRefAsPrimary
      ? assessment?.Assessment.AssessmentReference
      : assessment?.Assessment.AssessmentNumber;

    if (
      assessmentNumber &&
      assessment?.Assessment.PropertyName_Address_Locality
    ) {
      return `${assessmentNumber} - ${assessment?.Assessment.PropertyName_Address_Locality}`;
    } else if (
      assessmentNumber &&
      !assessment?.Assessment.PropertyName_Address_Locality
    ) {
      return "" + assessmentNumber;
    } else if (
      !assessmentNumber &&
      assessment?.Assessment.PropertyName_Address_Locality
    ) {
      return `${assessment?.Assessment.PropertyName_Address_Locality}`;
    }
    return "";
  }, [assessment]);

  const isVisibleWithoutLLSAndGeneralAccordion = useMemo(() => {
    return !isLLS && !isGeneral;
  }, [isGeneral, isLLS]);

  const listPanelBar: IPropertyPanelBar[] = [
    {
      title: "Actions and Workflows",
      component: (
        <PropertyActions
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_Assessment}
          componentNumber={eComponent.Assessment}
        />
      ),
      accordionType: eAccordionType.Actions_And_Workflows,
    },
    {
      title: `Associated ${assessmentLabel}`,
      component: (
        <AssociateAssessmentAccordion assessmentId={assessmentId ?? 0} />
      ),
    },
    {
      title: "Annual Returns",
      component: <AnnualStock assessmentId={assessmentId ?? 0} />,
      isVisible: isLLS,
    },
    {
      title: "Contacts",
      component: <Contacts />,
    },
    {
      title: "Transactions",
      component: <Transactions />,
    },
    {
      title: "Rate Breakup",
      component: <RateBreakup />,
      // [10482] Assessment - Child Screen - Rate Breakup: hide this accordion => Waiting new feature implement
      isVisible: false,
    },
    {
      title: "Related",
      component: (
        <PropertyAssociations
          id={assessmentId}
          componentNumber={eComponent.Assessment}
          ref={associationsRef}
        />
      ),
    },
    {
      title: "Valuations",
      component: (
        <Valuations
          assessmentId={assessmentId || 0}
          valuationNumber={assessment?.Assessment?.ValuationNumberX}
        />
      ),
    },
    {
      title: "Registers",
      component: <Registers />,
      isVisible: !isActro && isVisibleWithoutLLSAndGeneralAccordion,
    },
    {
      title: "Transfers",
      component: (
        <Transfer
          transferRef={isExpandedTransfer ? scrollToViewRef : undefined}
        />
      ),
      isExpanded: isExpandedTransfer,
    },
    {
      title: "Attributes",
      component: <AssessmentAttributesUDA />,
      isVisible: !isActro && !isLLS,
    },
    {
      title: "Charges",
      component: <Charges />,
    },
    {
      title: "Amounts Owing",
      component: <AmountOwing />,
      isVisible: !isLLS,
    },
    // Requirement: https://dev.azure.com/openofficeau/Radium/_workitems/edit/12802/
    // {
    //   title: "Audit",
    //   component: (
    //     <PropertyAudit
    //       id={parseInt(id)}
    //       componentNumber={eComponent.Assessment}
    //     />
    //   ),
    // },
    {
      title: "Arrangement",
      component: <Arrangement />,
      isVisible: !isActro && !isLLS,
    },
    {
      title: "Interest",
      component: <Interest />,
      isVisible: !isLLS,
    },
    {
      title: "Rating",
      component: (
        <Rating ratingRef={isExpandedRating ? scrollToViewRef : undefined} />
      ),
      isExpanded: isExpandedRating,
    },
    {
      title: "Parcels",
      component: <Parcels assessmentId={assessmentId || 0} />,
      isVisible: !isLLS, //Hide Parcel accordion for LLS
    },
    {
      title: "Rebates",
      component: (
        <Rebates
          assessmentId={assessmentId || 0}
          rebateRef={isExpandedRebate ? scrollToViewRef : undefined}
        />
      ),
      isExpanded: isExpandedRebate,
    },
    {
      title: "Levy Balances",
      component: <LevyBalances />,
      isVisible: !isLLS,
    },
    {
      title: "Certificates",
      component: <Certificates />,
    },
    {
      title: "Comments",
      component: (
        <PropertyComments
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_Assessment}
        />
      ),
    },
    {
      title: "Lineage",
      component: (
        <LineageAccordion
          apiUrl={`/api/property/internal/assessment/${parseInt(id)}/lineage`}
          redirectUrl={ASSESSMENT_ROUTE}
        />
      ),
    },
    {
      title: "Debt Recovery",
      component: <AssessmentDebtRecovery />,
      isVisible: isVisibleWithoutLLSAndGeneralAccordion,
    },
    {
      title: "Compliance",
      component: <Compliance assessmentId={parseInt(id)} />,
      isVisible: isVisibleWithoutLLSAndGeneralAccordion,
    },
    {
      title: "Land Rent",
      component: <LandRent />,
      isVisible: isActro, //14530 - Hide for Glenorchy
    },
    {
      title: "Collections",
      component: (
        <PropertyCollections
          componentId={parseInt(id)}
          componentNumber={eComponent.Assessment}
        />
      ),
    },
    {
      title: "Meters",
      component: <Meters />,
      isVisible: !isActro && isVisibleWithoutLLSAndGeneralAccordion,
    },
    {
      title: "Deferments",
      component: (
        <Deferments
          defermentsRef={isExpandedDeferment ? scrollToViewRef : undefined}
        />
      ),
      isExpanded: isExpandedDeferment,
      isVisible: isVisibleWithoutLLSAndGeneralAccordion,
    },
    {
      title: "Documents",
      component: (
        <PropertyDocuments
          componentId={parseInt(id)}
          componentNumber={eComponent.Assessment}
        />
      ),
    },
    {
      title: "Notices",
      component: <Notices />,
    },
    {
      title: "SAP Transactions",
      component: (
        <SAPTransaction sapId={assessment?.Assessment?.SAPHoldingId} />
      ),
      isVisible: isLLS,
    },
    {
      title: "GIS References",
      component: (
        <GISAccordion
          apiUrl={`/api/property/int/gisreference/${
            eComponent.Assessment
          }/${parseInt(id)}`}
        />
      ),
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation
        title={assessmentLabel}
        id={`cc-form-title-${ECustomColNameProperty.Assessment}`}
        badge={[
          assessment?.BannerStatus,
          assessment?.Assessment?.QuarantineStatus,
        ]}
      />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadAssessment(+id)}
        />
      ) : (
        <>
          <FormTitle title={getTitleHeader} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <CCNavButton
                  title={assessmentLabel ?? ""}
                  id={`cc-nav-${ECustomColNameProperty.Assessment}`}
                  type="dropdown"
                >
                  <AssociateAssessmentButton />
                  <AmalgamateAssessmentManagePageButton />
                  <CreateDeferredDutyButton isHidden={isLLS || isGeneral} />
                  <DisAssociateAssessmentButton />
                  <ModifyAssessmentButton />
                  <ReactivateAssessmentButton />
                  <SplitAssessmentButton isHidden={isActro} />
                  <SubdivideConsolidateButton
                    associationsRef={associationsRef}
                    isHidden={isActro}
                  />
                </CCNavButton>
                <CollectionsButton
                  id={parseInt(id)}
                  propertyComponent={eComponent.Assessment}
                />
                <CCNavButton
                  title={"Compliance"}
                  type="dropdown"
                  invisible={isLLS || isGeneral}
                >
                  <LandTaxExemptionButton />
                </CCNavButton>
                <CCNavButton
                  title={"Debt recovery"}
                  type="dropdown"
                  invisible={isLLS || isGeneral}
                >
                  <CreateDebtRecoveryExemptionButton />
                  <NewDebtRecoveryButton />
                </CCNavButton>
                <CCNavButton
                  title={"Deferment"}
                  type="dropdown"
                  invisible={isLLS || isGeneral}
                >
                  <CreateDefermentAssessmentButton />
                  <DeleteDeferment />
                </CCNavButton>
                <CCNavButton title={"Journal"} type="dropdown">
                  <AdjustChargeButton />
                  <AdjustChargeBalancesButton chargeData={chargesSelected} />
                  <ModifyChargeDetailsButton />
                  <NewAssessmentTransactionButton />
                  {/* Temporarily hide this menu due to an API maintenance.
									TODO: re-open it in the coming sprints, probably on demand or when the API is ready.
									refs: #12941 */}
                  {/* <RaiseAdhocChargeButton isHidden={isActro} /> */}
                  <RaiseChargeButton />
                  <RaiseAssessmentInterestButton isShowAssessmentInfo />
                  <ReverseTransactionButton />
                  <TransferTransactionButton />
                </CCNavButton>
                <CCNavButton
                  title="Land rent"
                  type="dropdown"
                  invisible={isGeneral || isLLS}
                >
                  <AssessmentLandRentActionButton />
                </CCNavButton>
                <CCNavButton
                  title={"Meter"}
                  type="dropdown"
                  invisible={isActro || isLLS || isGeneral}
                >
                  <EnterSpecialReadingButton />
                  <RemoveMeterButton />
                  <ReplaceMeterButton
                    meterInfo={{
                      ...meterSelected[0],
                      PropertyAddress:
                        assessment?.Assessment.PropertyName_Address_Locality,
                    }}
                    disabled={meterSelected.length !== 1}
                    onReplace={(data: IMeterFormGeneral) => {
                      pushNotification({
                        title: `Meter has been successfully replaced by meter - ${
                          data?.MeterNumber && data?.MeterNumber
                        }`,
                        type: "success",
                      });
                    }}
                  />
                  <ScheduleSpecialReadingsButton />
                  <TransferButton />
                </CCNavButton>
                <CCNavButton title={"Ownership"} type="dropdown">
                  <CancelOwnerChangeButton />
                  <ChangeOfOwnerShipButton
                    componentNumber={eComponent.Assessment}
                  />
                  <EditChangeOfOwnershipAssessmentButton />
                  <AddToNoticeGroupButton isHidden={isLLS} />
                </CCNavButton>
                {isLLS ? (
                  <CCNavButton title={"PIC"} type="dropdown">
                    <CreatePICButton assessmentIds={[+id]} />
                  </CCNavButton>
                ) : (
                  <></>
                )}
                <CCNavButton title="Rebates" type="dropdown">
                  <AddRebateButton />
                  <UpdateLossOfEligibilityDateButton />
                </CCNavButton>
                <CCNavButton title={"Supplementary"} type="dropdown">
                  <AddToSupplementaryButton
                    componentNumber={eComponent.Assessment}
                    statusId={assessment?.Assessment?.StatusId}
                  />
                </CCNavButton>
              </CCNavButton>,
              <CCNavButton title={"Add"} type="sub">
                <AddActionWithParentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Assessment}
                />
                <AddAttachmentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Assessment}
                  documentCategoryTypeId={
                    eDocumentTypeCategory.Assessment_Document
                  }
                />
                <AddCommentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Assessment}
                />
                <MailMergeButtonManagePage
                  productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
                  dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Assessment}
                  recordType={RECORDTYPE.CommunityProperty_Assessment}
                  isEmptyGridSelectedRow={!parseInt(id)}
                  selectedRecordIds={[parseInt(id)]}
                />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {assessment && (
                  <CCGeneralPanel component={<PropertyAssessmentForm />} />
                )}
                <PropertyPanelBar
                  initialPanels={listPanelBar}
                  componentId={parseInt(id)}
                  componentNumber={eComponent.Assessment}
                />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <AssessmentDetailTab id={assessmentId} />,
                    },
                    {
                      title: "Map",
                      component: (
                        <AssessmentMapTab
                          address={
                            assessment?.Assessment
                              ?.PropertyName_Address_Locality
                          }
                        />
                      ),
                    },
                    {
                      title: "Contacts",
                      component: (
                        <PropertyContactTab
                          id={assessmentId}
                          componentNumber={eComponent.Assessment}
                        />
                      ),
                    },
                    {
                      title: "Related",
                      component: <AssessmentsAssociationsTab />,
                    },
                    {
                      title: "History",
                      component: (
                        <PropertyHistoryTab
                          recordType={RECORDTYPE.CommunityProperty_Assessment}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
