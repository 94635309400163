import { eventEmitter } from "@/App";
import { history } from "@/AppRoutes";
import { CCJournalEventType } from "@app/core/journal/util";
import { postCheckIsSuperAdmin } from "@app/core/notices/[id]/api";
import { TabTableEventType } from "@app/core/tab-table/constant";
import {
  getAnimalById,
  getAnimalsInfo,
  getCoreLinkList,
  getTotalRelatedAnimalRecords,
  postAnimal,
  postIssueRegistrationSetup,
  postUpdateRegistration,
  postWorkflowDeceased,
  postWorkflowDeregister,
  postWorkflowIssueRegistration,
  postWorkflowLostTag,
  postWorkflowNewTagIssue,
  postWorkflowReactivate,
  postWorkflowRenew,
  registrationHandler,
} from "@app/products/animals/[id]/api";
import { ANIMALS_ROUTE } from "@app/products/animals/[id]/constant";
import {
  ANI_NEXTACTION,
  AnimalTypeSubmitActions,
  AnimalsLOVs,
  CoreLink,
  IParentAnimalsRegistrationSection,
  Registration,
  RegistrationHandlerRequest,
  RegistrationUIControl,
  Svc_FormAction_Registration,
  Svc_Registration_Workflow_Issue,
} from "@app/products/animals/[id]/model";
import { postRegenerateFee } from "@app/products/animals/components/action-bar/buttons/regenerate-fee/api";
import { AnimalType } from "@app/products/animals/model";
import { POUND_REGISTER_ROUTE } from "@app/products/animals/pound-register/[id]/constant";
import { IParentPoundRegisterSection } from "@app/products/animals/pound-register/[id]/model";
import { DBRowState } from "@app/products/crms/[id]/model";
import { ProvideReason } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/model";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { LinkType } from "@common/constants/linkType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { APIResponseStatus } from "@common/constants/response-status";
import { DateFromDateTo } from "@common/dialog/date-to-from/model";
import { RefNumberUsage } from "@common/input-pickers/debtor-number/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { cloneDeep, isNil } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class AnimalStore {
  private _isLoading: boolean = false;
  private _isLoadingDetails: boolean = false;
  private _isLoadingSuperAdmin: boolean = false;
  private _isSuperAdmin: boolean = false;
  private _OSFee: number = 0;
  private _RBal?: number = undefined;
  private _totalRelatedAnimalRecords: number = 0;
  private _isInactive: boolean = false;
  private _isFormModified: boolean = false;
  private _isReadOnlyRestrictedBreed: boolean = true;
  private _animalInfo?: Registration = undefined;
  private _animalLOVs?: AnimalsLOVs = undefined;
  private _animalBreedLOVs?: IKeyValuePacket[] = undefined;
  private _responseLoadError?: APIResponseError = undefined;
  private _responseDetailLoadError?: APIResponseError = undefined;
  private _UIControl?: RegistrationUIControl = undefined;
  private _restrictedBreedListID?: number[] = undefined;
  private _parent?: IParentAnimalsRegistrationSection = undefined;
  private _alerts?: IAppNotificationItemAddProps[] = undefined;
  private _pidNo?: string = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;

  private _isWorkflowLoading: boolean = false;
  private _isShowWorkflowDeregister: boolean = false;
  private _isShowWorkflowLostTag: boolean = false;
  private _isShowWorkflowRenew: boolean = false;
  private _isShowWorkflowDeceased: boolean = false;
  private _isLoadingRegenerateFee: boolean = false;
  private _isShowWorkflowReactivate: boolean = false;
  private _isShowIssueDialog: boolean = false;
  private _isShowPickDebtorDialog: boolean = false;
  private _isShowUpdateRegistrationDialog: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  //#region Store Property
  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get responseDetailLoadError() {
    return toJS(this._responseDetailLoadError);
  }
  setResponseDetailLoadError = (responseDetailLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseDetailLoadError = responseDetailLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isLoadingRegenerateFee() {
    return this._isLoadingRegenerateFee;
  }
  setIsLoadingRegenerateFee = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoadingRegenerateFee = isLoading;
    });
  };

  get totalRelatedAnimalRecords() {
    return this._totalRelatedAnimalRecords;
  }
  setTotalRelatedAnimalRecords = (totalRelatedAnimalRecords: number) => {
    runInAction(() => {
      this._totalRelatedAnimalRecords = totalRelatedAnimalRecords;
    });
  };

  get OSFee() {
    return this._OSFee;
  }
  setOSFee = (OSFee: number) => {
    runInAction(() => {
      this._OSFee = OSFee;
    });
  };

  get RBal() {
    return this._RBal;
  }
  setRBal = (RBal?: number) => {
    runInAction(() => {
      this._RBal = RBal;
    });
  };

  get isLoadingDetails() {
    return this._isLoadingDetails;
  }
  setIsLoadingDetails = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoadingDetails = isLoading;
    });
  };

  get isLoadingSuperAdmin() {
    return this._isLoadingSuperAdmin;
  }
  setIsLoadingSuperAdmin = (isLoadingSuperAdmin: boolean) => {
    runInAction(() => {
      this._isLoadingSuperAdmin = isLoadingSuperAdmin;
    });
  };

  get isSuperAdmin() {
    return toJS(this._isSuperAdmin);
  }
  setIsSuperAdmin = (isSuperAdmin: boolean) => {
    runInAction(() => {
      this._isSuperAdmin = isSuperAdmin;
    });
  };

  get UIControl() {
    return toJS(this._UIControl);
  }
  setUIControl = (UIControl: RegistrationUIControl) => {
    runInAction(() => {
      this._UIControl = UIControl;
    });
  };

  get isInactive() {
    return this._isInactive;
  }
  setIsInactive = (isInactive: boolean) => {
    runInAction(() => {
      this._isInactive = isInactive;
    });
  };

  get animalInfo() {
    return this._animalInfo;
  }
  setAnimalInfo = (animalInfo?: Registration) => {
    runInAction(() => {
      this._animalInfo = animalInfo;
    });
  };

  get animalLOVs() {
    return this._animalLOVs;
  }
  setAnimalLOVs = (animalLOVs?: AnimalsLOVs) => {
    runInAction(() => {
      this._animalLOVs = animalLOVs;
    });
  };

  get animalBreedLOVs() {
    return this._animalBreedLOVs;
  }
  setAnimalBreedLOVs = (_animalBreedLOVs?: IKeyValuePacket[]) => {
    runInAction(() => {
      this._animalBreedLOVs = _animalBreedLOVs;
    });
  };

  get restrictedBreedListID() {
    return toJS(this._restrictedBreedListID);
  }
  setRestrictedBreedListID = (restrictedBreedListID: number[]) => {
    runInAction(() => {
      this._restrictedBreedListID = restrictedBreedListID;
    });
  };

  get animalId() {
    return toJS(this._animalInfo?.Registration_ID);
  }

  get isFormModified() {
    return this._isFormModified;
  }
  setIsFormModified = (isFormModified: boolean) => {
    runInAction(() => {
      this._isFormModified = isFormModified;
    });
  };

  get parent() {
    return toJS(this._parent);
  }
  setParent = (parent?: IParentAnimalsRegistrationSection) => {
    runInAction(() => {
      this._parent = parent;
    });
  };

  get isReadOnlyRestrictedBreed() {
    return this._isReadOnlyRestrictedBreed;
  }
  setIsReadOnlyRestrictedBreed = (isReadOnlyRestrictedBreed: boolean) => {
    runInAction(() => {
      this._isReadOnlyRestrictedBreed = isReadOnlyRestrictedBreed;
    });
  };

  get alerts() {
    return this._alerts;
  }
  setAlerts = (alerts: IAppNotificationItemAddProps[] | undefined) => {
    runInAction(() => {
      this._alerts = alerts;
    });
  };

  get pidNo() {
    return this._pidNo;
  }
  setPidNo = (pidNo: string | undefined) => {
    runInAction(() => {
      this._pidNo = pidNo;
    });
  };

  //workflow
  get isWorkflowLoading() {
    return this._isWorkflowLoading;
  }
  setIsWorkflowLoading = (isWorkflowLoading: boolean) => {
    runInAction(() => {
      this._isWorkflowLoading = isWorkflowLoading;
    });
  };

  get isShowWorkflowDeregister() {
    return this._isShowWorkflowDeregister;
  }
  setIsShowWorkflowDeregister = (isShowWorkflowDeregister: boolean) => {
    runInAction(() => {
      this._isShowWorkflowDeregister = isShowWorkflowDeregister;
    });
  };

  get isShowWorkflowLostTag() {
    return this._isShowWorkflowLostTag;
  }
  setIsShowWorkflowLostTag = (isShowWorkflowLostTag: boolean) => {
    runInAction(() => {
      this._isShowWorkflowLostTag = isShowWorkflowLostTag;
    });
  };

  get isShowWorkflowRenew() {
    return this._isShowWorkflowRenew;
  }
  setIsShowWorkflowRenew = (isShowWorkflowRenew: boolean) => {
    runInAction(() => {
      this._isShowWorkflowRenew = isShowWorkflowRenew;
    });
  };

  get isShowWorkflowDeceased() {
    return this._isShowWorkflowDeceased;
  }
  setIsShowWorkflowDeceased = (isShowWorkflowDeceased: boolean) => {
    runInAction(() => {
      this._isShowWorkflowDeceased = isShowWorkflowDeceased;
    });
  };

  get isShowWorkflowReactivate() {
    return this._isShowWorkflowReactivate;
  }
  setIsShowWorkflowReactivate = (isShowWorkflowReactivate: boolean) => {
    runInAction(() => {
      this._isShowWorkflowReactivate = isShowWorkflowReactivate;
    });
  };

  get isShowIssueDialog() {
    return this._isShowIssueDialog;
  }
  setIsShowIssueDialog = (isShowIssueDialog: boolean) => {
    runInAction(() => {
      this._isShowIssueDialog = isShowIssueDialog;
    });
  };

  get isShowPickDebtorDialog() {
    return this._isShowPickDebtorDialog;
  }
  setIsShowPickDebtorDialog = (isShowPickDebtorDialog: boolean) => {
    runInAction(() => {
      this._isShowPickDebtorDialog = isShowPickDebtorDialog;
    });
  };

  get isShowUpdateRegistrationDialog() {
    return this._isShowUpdateRegistrationDialog;
  }
  setIsShowUpdateRegistrationDialog = (
    isShowUpdateRegistrationDialog: boolean
  ) => {
    runInAction(() => {
      this._isShowUpdateRegistrationDialog = isShowUpdateRegistrationDialog;
    });
  };
  //#endregion

  //#region Store Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._isInactive = false;
      this._parent = undefined;
      this._OSFee = 0;
      this._totalRelatedAnimalRecords = 0;
      this._isReadOnlyRestrictedBreed = true;
      this._animalInfo = undefined;
      this._animalLOVs = undefined;
      this._animalBreedLOVs = undefined;
      this._responseLoadError = undefined;
      this._responseDetailLoadError = undefined;
      this._UIControl = undefined;
      this._restrictedBreedListID = [];
      this._alerts = undefined;
      this._pidNo = undefined;
      this._RBal = undefined;
      //workflow
      this._isWorkflowLoading = false;
      this._isShowWorkflowDeregister = false;
      this._isShowWorkflowLostTag = false;
      this._isShowWorkflowRenew = false;
      this._isShowWorkflowDeceased = false;
      this._isLoadingRegenerateFee = false;
      this._isShowWorkflowReactivate = false;
      this._isShowIssueDialog = false;
      this._isShowPickDebtorDialog = false;
      this._isShowUpdateRegistrationDialog = false;
    });
  };

  runActions = (
    action: AnimalTypeSubmitActions,
    response?: APIResponse,
    animalRegistrationObj?: Registration
  ) => {
    switch (action) {
      case AnimalTypeSubmitActions.Save:
        appNotificationStore.clearErrorNotification();
        appNotificationStore.pushNotification({
          title: "Animal registration saved successfully.",
          type: "success",
        });
        break;
      case AnimalTypeSubmitActions.New:
        appNotificationStore.clearErrorNotification();
        history.replace(`${ANIMALS_ROUTE}/${response?.data?.ID}`, {
          parent: {
            notification: [
              {
                title: "Animal registration created successfully.",
                type: "success",
              },
            ],
          } as IParentAnimalsRegistrationSection,
        });
        break;
      case AnimalTypeSubmitActions.SaveAndBackPound:
        appNotificationStore.clearErrorNotification();
        history.replace(`${POUND_REGISTER_ROUTE}/${this.parent?.poundId}`, {
          parent: {
            notification: [
              {
                title: "Animal registration created successfully.",
                type: "success",
              },
            ],
            newAnimalsId: response?.data?.ID,
          } as IParentPoundRegisterSection,
        });
        break;
      case AnimalTypeSubmitActions.SameDetails:
        appNotificationStore.clearErrorNotification();
        history.push(`${ANIMALS_ROUTE}/new`, {
          parent: {
            isSameDetails: true,
            data: animalRegistrationObj,
          } as IParentAnimalsRegistrationSection,
        });
        break;
      case AnimalTypeSubmitActions.IssueRegistration:
        this.handleWorkflowIssue(animalRegistrationObj);
        break;
      case AnimalTypeSubmitActions.NewTagIssue:
        this.handleWorkflowNewTagIssue(animalRegistrationObj);
        break;
      default:
        break;
    }
  };
  //#endregion

  //#region Store Function
  loadCoreLinkList = async () => {
    const response = await getCoreLinkList(
      ECorporateSettingsField.Animals_RestrictedBreeds,
      RECORDTYPE.CORE_GlobalSettings,
      LinkType.Animals_RestrictedBreed
    );
    if (isSuccessResponse(response) && response?.data) {
      const restrictedBreeds: CoreLink[] = response?.data ?? [];
      const restrictedBreedsID: number[] = [];
      restrictedBreeds.forEach((item: CoreLink) => {
        restrictedBreedsID.push(item.Child_ID);
      });
      if (restrictedBreeds.length > 0) {
        this.setRestrictedBreedListID(restrictedBreedsID);
      }
    } else {
      this.setResponseLoadError({
        status: response.status,
        error: response?.error ?? "Load core link list failed.",
      });
    }
  };

  checkIsSuperAdmin = async (userID: number) => {
    this.setIsLoadingSuperAdmin(true);
    this.setResponseLoadError(undefined);
    const response = await postCheckIsSuperAdmin(userID);
    if (isSuccessResponse(response)) {
      this.setIsSuperAdmin(response.data ?? false);
    } else {
      this.setResponseLoadError({
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      });
    }
    this.setIsLoadingSuperAdmin(false);
  };

  loadTotalRelatedAnimalRecords = async (id: number, pid: string) => {
    const response = await getTotalRelatedAnimalRecords(id, pid);
    if (isSuccessResponse(response) && !isNil(response?.data)) {
      this.setTotalRelatedAnimalRecords(response?.data);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Load number of related animal records failed.",
        type: "error",
        description: response.error,
      });
    }
  };

  loadAnimalsInfo = async (id: number, isNew?: boolean) => {
    appNotificationStore.clearNotifications();
    let errorResponse = undefined;
    this.setResponseLoadError(undefined);
    this.setIsLoading(true);
    this.setIsLoadingDetails(true);
    const response = await getAnimalsInfo(isNew ? 0 : id);
    if (Array.isArray(response)) {
      const [animalInfo, animalLOVs] = response;
      this.setAnimalLOVs(animalLOVs.data);

      if (animalInfo?.data?.ReturnObj?.Registration) {
        var processAnimalInfo = this.processFromParent(
          animalInfo?.data?.ReturnObj.Registration
        );
        let params: RegistrationHandlerRequest = {
          FormAction: Svc_FormAction_Registration.SystemInitialise,
          Registration: processAnimalInfo,
          RegistrationArgs: {},
          IsFirstTimeLoad: true,
        };
        const responseHandler = await registrationHandler(params);
        if (
          isSuccessResponse(responseHandler) &&
          responseHandler.data?.ReturnObj
        ) {
          const returnObj = responseHandler.data?.ReturnObj;
          await this.loadCoreLinkList();
          if (!isNew) {
            await this.loadTotalRelatedAnimalRecords(
              animalInfo?.data?.ReturnObj.Registration.Registration_ID,
              animalInfo?.data?.ReturnObj.Registration?.Address
                ?.PropertyAssessment?.PID_No
            );
          }
          this.setAnimalInfo(returnObj?.Registration);
          this.firstLoadViewState();
          this.setUIControl(responseHandler.data.ReturnObj.UIControl);
          this.setOSFee(animalInfo?.data?.ReturnObj?.OSFee ?? 0);
          this.setRBal(animalInfo?.data?.ReturnObj?.RBal);
          this.setPidNo(
            returnObj?.Registration?.Address?.PropertyAssessment?.PID_No
          );

          // Customer Alert
          let alerts: IAppNotificationItemAddProps[] = [];
          if (returnObj.Registration?.Contact?.Contact?.ContactAlert) {
            alerts.push({
              title: `Contact alert: ${returnObj.Registration?.Contact?.Contact?.ContactAlert}`,
              type: "warning",
              autoClose: false,
            });
          }
          // Property Alert
          if (returnObj.Registration?.Address?.PropertyAlert) {
            alerts.push({
              title: `Property alert: ${returnObj.Registration?.Address?.PropertyAlert}`,
              type: "warning",
              autoClose: false,
            });
          }
          // Set alerts
          if (alerts?.length > 0) {
            this.setAlerts(alerts);
          }

          if (animalInfo.data?.Notifications?.length > 0) {
            appNotificationStore.pushNotification({
              autoClose: true,
              title: "Important Note",
              type: "info",
              description: animalInfo.data.Notifications,
            });
          }

          if (this.parent?.notification) {
            this.parent?.notification.forEach(
              (notification: IAppNotificationItemAddProps) => {
                appNotificationStore.pushNotification(notification);
              }
            );
            this.setParent({ ...this.parent, notification: [] });
          }
        } else {
          this.setResponseLoadError({
            status: responseHandler.status,
            error: responseHandler.data?.Errors ?? responseHandler.error,
          });
        }
      }

      if (!isNew && animalInfo?.data?.ReturnObj?.Registration) {
        if (
          animalInfo?.data?.ReturnObj?.Registration.Sys_DBRowState ===
          DBRowState.Inactive
        ) {
          this.setIsInactive(true);
          appNotificationStore.clearNotifications();
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Important Note",
            type: "info",
            description:
              "You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
          });
        } else {
          this.setIsInactive(false);
        }
      }

      // Push alerts
      if (this.alerts && this.alerts.length > 0) {
        this.alerts.forEach((alert) => {
          appNotificationStore.pushNotification(alert);
        });
        this.setAlerts(undefined);
      }
    } else {
      const responseError = response as APIResponse;
      errorResponse = {
        status: responseError.status,
        error: responseError.error,
      };
    }
    if (errorResponse) this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
    this.setIsLoadingDetails(false);
  };

  reloadAnimalRegistration = async () => {
    if (this.animalId) {
      return await this.loadAnimalsInfo(this.animalId);
    }
    return false;
  };

  saveAnimalRegistration = async (
    data: Registration,
    action: AnimalTypeSubmitActions
  ) => {
    if (
      action === AnimalTypeSubmitActions.SameDetails ||
      action === AnimalTypeSubmitActions.IssueRegistration ||
      action === AnimalTypeSubmitActions.NewTagIssue
    ) {
      this.runActions(action, undefined, data);
    } else if (action === AnimalTypeSubmitActions.RegenerateFee) {
      this.setIsLoadingRegenerateFee(true);
      if (isNil(data?.ReferenceNumber)) {
        data.ReferenceNumber = "";
      }
      if (isNil(data?.FileNumber)) {
        data.FileNumber = "";
      }
      const response = await postAnimal(data);
      if (isSuccessIdentityPacket(response)) {
        const responseRegenerateFee = await postRegenerateFee([
          data.Registration_ID,
        ]);
        this.setIsLoadingRegenerateFee(false);

        await this.loadAnimalsInfo(data.Registration_ID);

        if (isSuccessResponse(responseRegenerateFee)) {
          appNotificationStore.pushNotification({
            title: "Regenerate fee successfully.",
            type: "success",
          });
        } else {
          appNotificationStore.pushNotification({
            autoClose: false,
            description: responseRegenerateFee.data?.Errors ?? response?.error,
            type: "error",
          });
        }
      } else {
        this.setIsLoadingRegenerateFee(false);
        const responseError = response as APIResponse;
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Save animal registration failed.",
          type: "error",
          description: responseError.data?.Errors ?? response?.statusText,
        });
      }
    } else {
      this.setIsLoading(true);
      if (isNil(data?.ReferenceNumber)) {
        data.ReferenceNumber = "";
      }
      if (isNil(data?.FileNumber)) {
        data.FileNumber = "";
      }
      const response = await postAnimal(data);
      this.setIsLoading(false);
      if (isSuccessIdentityPacket(response)) {
        if (this.animalId) await this.loadAnimalsInfo(this.animalId);
        this.runActions(action, response);
      } else {
        const responseError = response as APIResponse;
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Save animal registration failed.",
          type: "error",
          description: responseError.data?.Errors ?? response?.statusText,
        });
      }
    }
  };

  loadRegistrationHandle = async (
    params: RegistrationHandlerRequest,
    errorMsg: string
  ) => {
    this.setIsLoading(true);
    const response = await registrationHandler(params);
    if (isSuccessResponse(response) && response.data?.ReturnObj) {
      this.setIsFormModified(true);
      const registration = response.data.ReturnObj.Registration;

      this.setAnimalInfo(registration);
      this.setUIControl(response.data.ReturnObj.UIControl);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: errorMsg,
        type: "error",
        description: response.data?.Errors || response.error,
      });
    }
    this.setIsLoading(false);
  };

  loadDetails = async (id: number) => {
    this.setIsLoadingDetails(true);
    this.setResponseDetailLoadError(undefined);
    const response = await getAnimalById(id);
    this.setIsLoadingDetails(false);
    if (isSuccessResponse(response) && response.data?.ReturnObj) {
      this.setAnimalInfo(response.data.ReturnObj.Registration);
      this.setOSFee(response?.data?.ReturnObj?.OSFee ?? 0);
    } else {
      this.setResponseDetailLoadError({
        status: response.status,
        error: response.data?.Errors ?? response.error,
      });
    }
  };
  //#endregion

  //#region State Load
  firstLoadViewState = () => {
    if (!isNil(this.animalInfo)) {
      if (isNil(this.animalInfo?.Flag_CertifiedAssistance)) {
        this.animalInfo.Flag_CertifiedAssistance = false;
      }
      // Restricted breed logic
      if (!isNil(this.animalInfo?.Breed_KWD)) {
        if (
          this.restrictedBreedListID &&
          this.restrictedBreedListID.length > 0
        ) {
          if (
            this.restrictedBreedListID.some(
              (idNumber) => idNumber === this.animalInfo?.Breed_KWD
            )
          ) {
            this.animalInfo.Flag_RestrictedBreed = true;
            this.setIsReadOnlyRestrictedBreed(true);
          } else {
            this.animalInfo.Flag_RestrictedBreed = false;
            this.setIsReadOnlyRestrictedBreed(false);
          }
        }
      }
      this.populateBreeds(
        this.animalInfo?.AnimalType_ID,
        this.animalInfo?.AnimalType
      );
    }
  };

  processFromParent = (animalInfo: Registration) => {
    if (this.parent?.isSameDetails && !isNil(this.parent?.data)) {
      var sameDetailsData = this.parent.data;
      animalInfo.Contact = sameDetailsData.Contact;
      animalInfo.Contact.RID = 0;

      animalInfo.Address = sameDetailsData.Address;
      animalInfo.Address.Address_ID = 0;

      animalInfo.Flag_Pensioner = sameDetailsData.Flag_Pensioner;
      animalInfo.Flag_HealthCareCard = sameDetailsData.Flag_HealthCareCard;
      animalInfo.PensionNumber = sameDetailsData.PensionNumber;
      animalInfo.Flag_ClubMember = sameDetailsData.Flag_ClubMember;
      animalInfo.ClubNumber = sameDetailsData.ClubNumber;
      //animalInfo.DebtorNumber = sameDetailsData.DebtorNumber; Radium logic is comment this line
    }
    return animalInfo;
  };

  populateBreeds = (animalTypeId?: number, animalType?: AnimalType) => {
    if (animalTypeId && animalType) {
      if (animalType.Flag_AnimalType === true) {
        this.setAnimalBreedLOVs(this.animalLOVs?.Animals_CatBreeds ?? []);
      } else if (animalType.Flag_AnimalType === false) {
        this.setAnimalBreedLOVs(this.animalLOVs?.Animals_DogBreeds ?? []);
      } else {
        this.setAnimalBreedLOVs(this.animalLOVs?.Animals_OtherBreeds ?? []);
      }
    }
  };
  //#endregion

  //#region Store Workflow
  handleWorkflowDeregister = async (
    registration: Registration,
    provideReason: ProvideReason,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => {
    this.setIsWorkflowLoading(true);
    const response = await postWorkflowDeregister({
      Registration: registration,
      ProvideReason: provideReason,
    });
    if (isSuccessIdentityPacket(response) && response?.data?.ReturnObj) {
      this.setAnimalInfo(response.data.ReturnObj);
      this.setIsShowWorkflowDeregister(false);
      appNotificationStore.clearNotifications();
      appNotificationStore.pushNotification({
        type: "success",
        title: "Deregister successfully.",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData);
      eventEmitter.emit(CCJournalEventType.RefreshData);
      eventEmitter.emit(TabTableEventType.RefreshData);
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: "Deregister failed.",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
    this.setIsWorkflowLoading(false);
  };

  handleWorkflowLostTag = async (
    registration: Registration,
    provideReason: ProvideReason,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => {
    this.setIsWorkflowLoading(true);
    const response = await postWorkflowLostTag({
      Registration: registration,
      ProvideReason: provideReason,
    });
    if (isSuccessIdentityPacket(response) && response?.data?.ReturnObj) {
      this.setAnimalInfo(response.data.ReturnObj);
      this.setIsShowWorkflowLostTag(false);
      appNotificationStore.clearNotifications();
      appNotificationStore.pushNotification({
        type: "success",
        title: "Updated lost tag successfully.",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData);
      eventEmitter.emit(CCJournalEventType.RefreshData);
      eventEmitter.emit(TabTableEventType.RefreshData);
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: "Update lost tag failed.",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
    this.setIsWorkflowLoading(false);
  };

  handleWorkflowNewTagIssue = async (registration?: Registration) => {
    if (isNil(registration)) return;
    this.setIsWorkflowLoading(true);
    const response = await postWorkflowNewTagIssue(registration);
    if (isSuccessIdentityPacket(response) && response?.data?.ReturnObj) {
      this.setAnimalInfo(response.data.ReturnObj);
      appNotificationStore.pushNotification({
        title: "New tag issue registration successfully.",
        type: "success",
      });
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "New tag issue registration failed.",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
    this.setIsWorkflowLoading(false);
  };

  handleWorkflowRenew = async (
    registration: Registration,
    dateFromDateTo: DateFromDateTo,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => {
    this.setIsWorkflowLoading(true);
    const response = await postWorkflowRenew({
      Registration: registration,
      DateFromDateTo: dateFromDateTo,
    });
    if (isSuccessIdentityPacket(response) && response?.data?.ReturnObj) {
      this.setAnimalInfo(response.data.ReturnObj);
      this.setIsShowWorkflowRenew(false);
      appNotificationStore.clearNotifications();
      appNotificationStore.pushNotification({
        type: "success",
        title: "Renew successfully.",
      });
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: "Renew failed.",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
    this.setIsWorkflowLoading(false);
  };

  handleWorkflowDeceased = async (
    registration: Registration,
    provideReason: ProvideReason,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => {
    this.setIsWorkflowLoading(true);
    const response = await postWorkflowDeceased({
      Registration: registration,
      ProvideReason: provideReason,
    });
    if (isSuccessIdentityPacket(response) && response?.data?.ReturnObj) {
      this.setAnimalInfo(response.data.ReturnObj);
      this.setIsShowWorkflowDeceased(false);
      appNotificationStore.clearNotifications();
      appNotificationStore.pushNotification({
        type: "success",
        title: "Updated deceased successfully.",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData);
      eventEmitter.emit(CCJournalEventType.RefreshData);
      eventEmitter.emit(TabTableEventType.RefreshData);
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: "Update deceased failed.",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
    this.setIsWorkflowLoading(false);
  };

  handleWorkflowReactivate = async (
    registration: Registration,
    provideReason: ProvideReason,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => {
    this.setIsWorkflowLoading(true);
    const response = await postWorkflowReactivate({
      Registration: registration,
      ProvideReason: provideReason,
    });
    if (isSuccessIdentityPacket(response) && response?.data?.ReturnObj) {
      this.setAnimalInfo(response.data.ReturnObj);
      this.setIsShowWorkflowReactivate(false);
      this.setIsShowIssueDialog(true);
      eventEmitter.emit(CCGridEventType.RefreshOData);
      eventEmitter.emit(CCJournalEventType.RefreshData);
      eventEmitter.emit(TabTableEventType.RefreshData);
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: "Reactivate failed.",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
    this.setIsWorkflowLoading(false);
  };

  handlePickDebtor = (value: RefNumberUsage[]) => {
    if (this.animalInfo && isNil(this.animalInfo?.DebtorNumber)) {
      var animalData: Registration | undefined = cloneDeep(this.animalInfo);
      animalData = {
        ...this.animalInfo,
        DebtorNumber: value[0].Sys_DebtorNumber,
      };
      this.setAnimalInfo(animalData);
    } else if (this.animalInfo && !isNil(this.animalInfo?.DebtorNumber)) {
      this.animalInfo.DebtorNumber = value[0].Sys_DebtorNumber;
    }
    this.setIsShowPickDebtorDialog(false);
    this.setIsShowIssueDialog(true);
  };

  handleWorkflowIssue = async (registration?: Registration) => {
    if (isNil(registration)) return;
    this.setIsWorkflowLoading(true);
    const response = await postIssueRegistrationSetup(registration);
    if (isSuccessIdentityPacket(response) && response?.data?.ReturnObj) {
      this.setAnimalInfo(response.data.ReturnObj.Registration);
      if (response.data.ReturnObj.AniNextAction === ANI_NEXTACTION.ISSUE)
        this.setIsShowIssueDialog(true);
      if (response.data.ReturnObj.AniNextAction === ANI_NEXTACTION.PICKDEBTOR) {
        this.setIsShowPickDebtorDialog(true);
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Issue registration failed.",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
    this.setIsWorkflowLoading(false);
  };

  handleWorkflowIssueRegistration = async (
    value: Svc_Registration_Workflow_Issue,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => {
    this.setIsWorkflowLoading(true);
    const response = await postWorkflowIssueRegistration(value);
    if (isSuccessIdentityPacket(response) && response?.data?.ReturnObj) {
      this.setIsShowIssueDialog(false);
      this.setAnimalInfo(response.data.ReturnObj);
      appNotificationStore.clearNotifications();
      appNotificationStore.pushNotification({
        type: "success",
        title: "Updated registration successfully.",
      });
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: "Updated registration failed.",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
    this.setIsWorkflowLoading(false);
  };

  handleUpdateRegistration = async (
    value: Svc_Registration_Workflow_Issue,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => {
    this.setIsWorkflowLoading(true);
    const response = await postUpdateRegistration(value);
    if (isSuccessIdentityPacket(response) && response?.data?.ReturnObj) {
      this.setIsShowUpdateRegistrationDialog(false);
      this.setAnimalInfo(response.data.ReturnObj);
      appNotificationStore.clearNotifications();
      appNotificationStore.pushNotification({
        type: "success",
        title: "Updated registration successfully.",
      });
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: "Updated registration failed.",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
    this.setIsWorkflowLoading(false);
  };
  //#endregion
}

const animalStoreContext = createContext(new AnimalStore());
export const useAnimalStore = () => {
  return useContext(animalStoreContext);
};
