import { DTO_ChecklistQuestion } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class NewCertificateDialogStore {
  private _dynamicQuestionList?: DTO_ChecklistQuestion[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get dynamicQuestionList() {
    return toJS(this._dynamicQuestionList);
  }
  setDynamicQuestionList = (dynamicQuestionList?: DTO_ChecklistQuestion[]) => {
    runInAction(() => {
      this._dynamicQuestionList = dynamicQuestionList;
    });
  };
}

const newCertificateDialogStoreContext = createContext(
  new NewCertificateDialogStore()
);
export const useNewCertificateDialogStore = () => {
  return useContext(newCertificateDialogStoreContext);
};
