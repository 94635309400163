import { Registration } from "@app/products/animals/[id]/model";
import { AnimalSettingValue } from "@app/products/animals/model";
import { SettingsMap } from "@app/products/property/system-admin/settings/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import {
  getBoolValueSetting,
  getNumberValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";

export const getValueFromSetting = (settings: SettingsMap) => {
  return {
    // Mandatory
    animalsMandatoryBreed: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_Breed]
    ),
    animalsMandatoryPurebreed: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_Purebreed]
    ),
    animalsMandatoryGender: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_Gender]
    ),
    animalsMandatoryColour: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_Colour]
    ),
    animalsMandatoryAnimalName: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_AnimalName]
    ),
    animalsMandatoryClubMember: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_ClubMember]
    ),
    animalsMandatoryCertifiedAssistance: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_CertifiedAssistance]
    ),
    animalsMandatoryDangerous: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_Dangerous]
    ),
    animalsMandatoryOrganisation: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_Organisation]
    ),
    animalsMandatoryMenacing: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_Menacing]
    ),
    animalsMandatoryGuardDog: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_GuardDog]
    ),
    animalsMandatoryWorking: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_Working]
    ),
    animalsMandatoryGuideDogAssistance: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_GuideDogAssistance]
    ),
    animalsMandatoryDogHomeAdoption: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_DogHomeAdoption]
    ),
    animalsMandatoryObedienceTrained: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_ObedienceTrained]
    ),
    animalsMandatoryCustomsDog: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_CustomsDog]
    ),
    animalsMandatorySecondaryColour: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_SecondaryColour]
    ),
    animalsMandatoryHealthCareCard: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_HealthCareCard]
    ),
    animalsMandatoryRestrictedBreed: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_RestrictedBreed]
    ),
    animalsMandatoryHuntingdog: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_Huntingdog]
    ),
    animalsMandatoryGreyhound: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_Greyhound]
    ),

    animalsMandatorySterlisedTypeOfProof: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_SterlisedTypeOfProof]
    ),
    animalsMandatoryObedienceTypeOfProof: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_ObedienceTypeOfProof]
    ),
    animalsMandatoryGuideDogTypeOfProof: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_GuideDogTypeOfProof]
    ),
    animalsMandatoryWorkingTypeOfProof: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_WorkingTypeOfProof]
    ),
    animalsMandatoryCustomsDogTypeOfProof: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_CustomsDogTypeOfProof]
    ),
    animalsMandatoryPureBreedTypeOfProof: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_PureBreedTypeOfProof]
    ),
    animalsMandatoryGuardDogTypeOfProof: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_GuardDogTypeOfProof]
    ),
    animalsMandatoryDogHomeAdoptionTypeOfProof: getBoolValueSetting(
      settings[
        ECorporateSettingsField.Animals_Mandatory_DogHomeAdoptionTypeOfProof
      ]
    ),
    animalsMandatoryHuntingDogTypeOfProof: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_HuntingDogTypeOfProof]
    ),
    animalsMandatoryGreyhoundTypeOfProof: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Mandatory_GreyhoundTypeOfProof]
    ),
    corporateSettingsDebtorNumberMandatory: getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_DebtorNumberMandatory]
    ),

    // Display field
    animalsDisplayPurebreed: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Display_Purebreed]
    ),
    animalsDisplayClubMember: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Display_ClubMember]
    ),
    animalsDisplayOrganisation: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Display_Organisation]
    ),
    animalsDisplayMenacing: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Display_Menacing]
    ),
    animalsDisplayGuardDog: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Display_GuardDog]
    ),
    animalsDisplayDogHomeAdoption: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Display_DogHomeAdoption]
    ),
    animalsDisplayObedienceTrained: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Display_ObedienceTrained]
    ),
    animalsDisplayCustomsDog: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Display_CustomsDog]
    ),
    animalsDisplaySecondaryColour: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Display_SecondaryColour]
    ),
    animalsDisplayHealthCareCard: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Display_HealthCareCard]
    ),
    animalsDisplayCertifiedAssistance: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Display_CertifiedAssistance]
    ),
    animalsDisplayRestrictedBreed: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Display_RestrictedBreed]
    ),
    animalsDisplayHuntingdog: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Display_Huntingdog]
    ),
    animalsDisplayGreyhound: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_Display_Greyhound]
    ),
    animalsEnableDOGExemption: getStringValueSetting(
      settings[ECorporateSettingsField.Animals_EnableDOGExemption]
    ),
    animalsEnablePensionerExpiry: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_EnablePensionerExpiry]
    ),
    animalsEnableTransfer: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_EnableTransfer]
    ),

    //Label
    animalsLabelPureBreed: getStringValueSetting(
      settings[ECorporateSettingsField.Animals_LabelPureBreed]
    ),
    animalsLabelWorking: getStringValueSetting(
      settings[ECorporateSettingsField.Animals_LabelWorking]
    ),
    animalsLabelGreyhound: getStringValueSetting(
      settings[ECorporateSettingsField.Animals_LabelGreyhound]
    ),
    animalsLabelGuideDogAssistance: getStringValueSetting(
      settings[ECorporateSettingsField.Animals_LabelGuideDogAssistance]
    ),
    animalsLabelDangerous: getStringValueSetting(
      settings[ECorporateSettingsField.Animals_LabelDangerous]
    ),
    animalsClassificationLabelPensioner_Sterilised: getStringValueSetting(
      settings[
        ECorporateSettingsField.Animals_ClassificationLabelPensioner_Sterilised
      ]
    ),
    animalsClassificationLabelPensioner_NotSterilised: getStringValueSetting(
      settings[
        ECorporateSettingsField
          .Animals_ClassificationLabelPensioner_NotSterilised
      ]
    ),
    animalsClassificationLabelNotPensioner_Sterilised: getStringValueSetting(
      settings[
        ECorporateSettingsField
          .Animals_ClassificationLabelNotPensioner_Sterilised
      ]
    ),
    animalsClassificationLabelNotPensioner_NotSterilised: getStringValueSetting(
      settings[
        ECorporateSettingsField
          .Animals_ClassificationLabelNotPensioner_NotSterilised
      ]
    ),
    corporateSettingsDebtorNumberLabel: getStringValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_DebtorNumberLabel]
    ),
    animalsLabelBreed: getStringValueSetting(
      settings[ECorporateSettingsField.Animals_LabelBreed]
    ),
    animalsLabelDOB: getStringValueSetting(
      settings[ECorporateSettingsField.Animals_LabelDOB]
    ),
    animalsLabelClubMember: getStringValueSetting(
      settings[ECorporateSettingsField.Animals_LabelClubMember]
    ),
    animalsLabelColour: getStringValueSetting(
      settings[ECorporateSettingsField.Animals_LabelColour]
    ),
    animalsLabelOrganisation: getStringValueSetting(
      settings[ECorporateSettingsField.Animals_LabelOrganisation]
    ),
    animalsLabelMenacing: getStringValueSetting(
      settings[ECorporateSettingsField.Animals_LabelMenacing]
    ),
    animalsLabelHuntingDog: getStringValueSetting(
      settings[ECorporateSettingsField.Animals_LabelHuntingDog]
    ),
    osFeesLabel: getStringValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_OSFeeLabel]
    ),

    //Other
    animalsRegistrationExpiryDate: getStringValueSetting(
      settings[ECorporateSettingsField.Animals_RegistrationExpiryDate]
    ),
    animalsRegistrationExpiryDateOption: getNumberValueSetting(
      settings[ECorporateSettingsField.Animals_RegistrationExpiryDateOption]
    ),
    registrationExpiryCalcEffectiveDate: getStringValueSetting(
      settings[
        ECorporateSettingsField.Animals_RegistrationExpiryCalcEffectiveDate
      ]
    ),
    corporateSettingsFinanceAllocateDebtorNumber: getBoolValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_FinanceAllocateDebtorNumber
      ]
    ),
    animalsAutoIssue: getBoolValueSetting(
      settings[ECorporateSettingsField.Animals_AutoIssue]
    ),
    corporateSettingsEnableDebtorUserSelection: getBoolValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_EnableDebtorUserSelection
      ]
    ),
  } as AnimalSettingValue;
};

export const calculateAnimalClassificationName = (
  animalRegistration: Registration | undefined,
  animalSettingValue: AnimalSettingValue | undefined
) => {
  let animalClassificationName = "";
  if (animalRegistration != null) {
    if (
      animalRegistration?.Flag_Farm &&
      animalSettingValue?.animalsLabelWorking
    )
      animalClassificationName = animalSettingValue.animalsLabelWorking;
    else if (
      animalRegistration?.IsPureBreed &&
      animalSettingValue?.animalsLabelPureBreed
    )
      animalClassificationName = animalSettingValue.animalsLabelPureBreed;
    else if (
      animalRegistration?.IsGreyhound &&
      animalSettingValue?.animalsLabelGreyhound
    )
      animalClassificationName = animalSettingValue.animalsLabelGreyhound;
    else if (
      animalRegistration?.Flag_GuideDog &&
      animalSettingValue?.animalsLabelGuideDogAssistance &&
      animalRegistration.Flag_Dangerous &&
      animalSettingValue?.animalsLabelDangerous
    )
      animalClassificationName =
        animalSettingValue.animalsLabelGuideDogAssistance +
        "/" +
        animalSettingValue.animalsLabelDangerous;
    else if (
      animalRegistration?.Flag_GuideDog &&
      animalSettingValue?.animalsLabelGuideDogAssistance
    )
      animalClassificationName =
        animalSettingValue.animalsLabelGuideDogAssistance;
    else if (
      animalRegistration?.Flag_Dangerous &&
      animalSettingValue?.animalsLabelDangerous
    )
      animalClassificationName = animalSettingValue.animalsLabelDangerous;
    else if (
      animalRegistration?.Flag_Pensioner &&
      animalRegistration?.Flag_Sterilised &&
      animalSettingValue?.animalsClassificationLabelPensioner_Sterilised
    )
      animalClassificationName =
        animalSettingValue.animalsClassificationLabelPensioner_Sterilised;
    else if (
      animalRegistration?.Flag_Pensioner &&
      !animalRegistration.Flag_Sterilised &&
      animalSettingValue?.animalsClassificationLabelPensioner_NotSterilised
    )
      animalClassificationName =
        animalSettingValue.animalsClassificationLabelPensioner_NotSterilised;
    else if (
      !animalRegistration?.Flag_Pensioner &&
      animalRegistration.Flag_Sterilised &&
      animalSettingValue?.animalsClassificationLabelNotPensioner_Sterilised
    )
      animalClassificationName =
        animalSettingValue.animalsClassificationLabelNotPensioner_Sterilised;
    else if (
      !animalRegistration?.Flag_Pensioner &&
      !animalRegistration.Flag_Sterilised &&
      animalSettingValue?.animalsClassificationLabelNotPensioner_NotSterilised
    )
      animalClassificationName =
        animalSettingValue.animalsClassificationLabelNotPensioner_NotSterilised;
  }
  return animalClassificationName;
};
