export interface DTO_CreateAssessment_Parcel {
  Status: string;
  LandUse: string;
  Zones: string;
  LandArea: string;
  FederalElectoralDivision: string;
  StateCodeElectoralDivision: string;
  ElectoralDivision: string;
  CensusDistrict: string;
  LegalDescription: string;
  Locality: string;
  ParcelReference: string;
  PropertyAddress: string;
  Parcel_Id: number;
  OwnerName: string;
  AssociationFactorPercentage: number;
  PropertyName: string;
  LandCategory: string;
  MapReference: string;
  MapNumber: string;
  LocationDescriptor: string;
}

export interface DTO_Title {
  PIC_Title_Id?: number;
  LinkedAssessments: DTO_Title_LinkedAssessment[];
  GISReference: string;
  Section: string;
  Parish: string;
  County: string;
  Frontage?: number;
  AssociatedPICAreaSet?: number;
  AssociatedPICArea?: number;
  AssociatedPICAreaTotal?: number;
  IsAreaSurveyed: boolean;
  NameAddress: string;
  LandArea?: number;
  Active_From?: Date;
  DivisionDate?: Date;
  Status: string;
  Folio: string;
  Volume: string;
  Lot: string;
  PlanNumber: string;
  PlanType: string;
  ReferenceNumber?: number;
  LegalDescription: string;
  TitleId: number;
  Coverage: string;
  Active_To?: Date;
  AssociationFactorPercentage?: number;
  StatusId?: number;
  Title_Part_Lot_Number: number;
  // Mock id for action
  RowId?: string;
}

export interface DTO_Title_LinkedAssessment {
  Assessment_Id: number;
  LinkedTitles: number[];
}

export enum EAssociationGridType {
  Parcel = "Parcel",
  Title = "Title",
}
