import {
  Permit,
  Permit_Status,
  Svc_Permit,
  Svc_Permit_Workflow_DateFromDateTo,
} from "@app/products/local-laws/[id]/model";
import { postIssuePermit } from "@app/products/local-laws/components/toolbar/buttons/issue-permit/api";
import { APIResponse } from "@common/apis/model";
import { DateToFromDialog } from "@common/dialog/date-to-from/_index";
import { DateFromDateTo } from "@common/dialog/date-to-from/model";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IIdentityPacket } from "@common/models/identityPacket";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { addMonths, addYears } from "date-fns";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

interface IIssuePermitButtonProps {
  isDisabled?: boolean;
}

export const IssuePermitButton = observer(
  ({ isDisabled }: IIssuePermitButtonProps) => {
    //#region STORE ========/
    const {
      dataForms,
      isLoadingForm,
      submitFormGetData,
      setMiddlewareSubmitFormOptions,
      updateFormFields,
    } = useFlexibleFormStore();
    const { settings } = useCommonCoreStore();
    const { cancelToken } = useCancelRequest();
    const { pushNotificationPortal } = useNotificationPortalStore();
    //#endregion STORE =====/

    //#region STATES ========/
    const [objEffectDateIssue, setObjEffectDateIssue] =
      useState<DateFromDateTo>();
    const [isShowWorkflowIssue, setIsShowWorkflowIssue] =
      useState<boolean>(false);
    const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);
    //#endregion STATES =====/

    //#region DECLARE ========/
    const showPermitEffectiveDates = getBoolValueSetting(
      settings[ECorporateSettingsField.LLP_ShowPermitEffectiveDates]
    );
    const placeId = showPermitEffectiveDates ? "Main" : "DateToFromDialog";

    const isVisible = useMemo(() => {
      const permitData = dataForms?.GeneralForm?.Permit as Permit;
      return permitData?.Status_ENUM === Permit_Status.PermitLodged;
    }, [dataForms]);
    //#endregion DECLARE =====/

    //#region METHOD ========/
    const objIssue = () => {
      const permitData = dataForms?.GeneralForm?.Permit as Permit;
      const objReason = new DateFromDateTo();
      objReason.Title_Text = "Issue Permit";
      objReason.DateLabelFrom_Text = "Effective from";
      objReason.DateLabelFrom_To = "Effective to";
      objReason.DateFrom = new Date();

      if (
        !isNil(permitData?.PermitType) &&
        permitData?.PermitType?.DefaultPeriodMonths
      ) {
        objReason.DateTo = addMonths(
          new Date(),
          permitData.PermitType.DefaultPeriodMonths
        );
      } else {
        objReason.DateTo = addYears(new Date(), 1);
      }

      setObjEffectDateIssue(objReason);
    };

    const isOpenIssueWfDialog = useMemo(() => {
      setObjEffectDateIssue(undefined);
      if (isShowWorkflowIssue) objIssue();
      return isShowWorkflowIssue;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowWorkflowIssue]);

    const handleClickButton = async () => {
      setMiddlewareSubmitFormOptions({ skipCheckModified: true });
      const dataForm = await submitFormGetData("GeneralForm");
      if (!isNil(dataForm?.Permit)) {
        if (showPermitEffectiveDates) {
          handleWorkflowIssue(null);
        } else {
          setIsShowWorkflowIssue(true);
        }
      }
    };

    const handleWorkflowIssue = async (
      dateFromDateTo: DateFromDateTo | null
    ) => {
      setMiddlewareSubmitFormOptions({ skipCheckModified: true });
      const generalForm = await submitFormGetData("GeneralForm");
      let permit: Permit = generalForm?.Permit;

      if (permit) {
        permit.TypeOfDescription = permit.TypeOfDescription ?? "";
        permit.ReferenceNumber = permit.ReferenceNumber ?? "";
        permit.FileNumber = permit.FileNumber ?? "";
        permit.InsurancePolicyNumber = permit.InsurancePolicyNumber ?? "";
        permit.Description = permit.Description ?? "";

        const responseRequest = {
          Permit: permit,
          DateFromDateTo: dateFromDateTo,
        } as Svc_Permit_Workflow_DateFromDateTo;

        await issuePermitButtonSlider.fetchApi({
          initialData: responseRequest,
        });
      }
    };

    const handleOnCloseDialog = () => {
      setIsShowWorkflowIssue(false);
      setObjEffectDateIssue(undefined);
    };

    const issuePermitButtonSlider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            const issuePermitRequest: Svc_Permit_Workflow_DateFromDateTo =
              initialData;
            if (showPermitEffectiveDates) {
              setIsLoadingButton(true);
            }
            return postIssuePermit(issuePermitRequest, cancelToken());
          },
          handleSuccess: ({ dataFromApi }) => {
            const response: APIResponse<IIdentityPacket<Permit>> = dataFromApi;
            const permitFormData = response?.data?.ReturnObj as Permit;
            if (showPermitEffectiveDates) {
              setIsLoadingButton(false);
            }
            if (response?.data?.ReturnObj) {
              //#region save data to store ========/
              updateFormFields("GeneralForm", {
                Permit: permitFormData,
              } as Svc_Permit);
              //#endregion save data to store =====/

              setIsShowWorkflowIssue(false);
              setObjEffectDateIssue(undefined);

              pushNotificationPortal({
                title: "Permit issued successfully.",
                type: "success",
              });
            } else {
              pushNotificationPortal({
                placeId: placeId,
                autoClose: false,
                title: "Issued permit failed.",
                type: "error",
                description: response.data?.Errors ?? response.statusText,
              });
            }
            return false;
          },
          handleError: ({ errorFromApi }) => {
            if (showPermitEffectiveDates) {
              setIsLoadingButton(false);
            }
            pushNotificationPortal({
              placeId: placeId,
              autoClose: false,
              title: "Issued permit failed.",
              type: "error",
              description: errorFromApi.error,
            });
          },
        },
      ],
    });
    //#endregion METHOD =====/

    return (
      <>
        <CCNavButton
          title="Issue permit"
          isLoading={isLoadingButton}
          invisible={!isVisible}
          onClick={handleClickButton}
          disabled={isLoadingForm || isLoadingButton || isDisabled}
        />

        {isOpenIssueWfDialog && (
          <DateToFromDialog
            onClose={handleOnCloseDialog}
            onSubmit={(value: DateFromDateTo) => handleWorkflowIssue(value)}
            isLoading={issuePermitButtonSlider.isFetching}
            data={objEffectDateIssue}
          />
        )}
      </>
    );
  }
);
