import { eventEmitter } from "@/App";
import { COMMENT_ACCORDION_GRID_ID } from "@app/core/comments/constant";
import { ResponsePacket } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/model";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { AssessmentTabDetailsEventType } from "@app/products/property/assessments/[id]/components/reference-sidebar/detail/config";
import { useAssessmentStore } from "@app/products/property/assessments/[id]/store";
import { ASSESSMENT_ROUTE } from "@app/products/property/assessments/components/form-steps/modify-assessment/util";
import { PhysicalAddressGridFormStep } from "@app/products/property/assessments/components/form-steps/new-assessment/components/form-elements/physical-address-grid/_index";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import {
  Collection,
  DTO_Assessment_LOVs,
  EKeysOfSteps,
  keysOfSendSteps,
} from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import {
  getInitialDataAssessment,
  postProcessReactivateAssessment,
} from "@app/products/property/assessments/components/form-steps/reactivate-assessment/api";
import { AssessmentDetailsFormStep } from "@app/products/property/assessments/components/form-steps/reactivate-assessment/components/form-elements/assessment-details/_index";
import { AssociationParcelsFormStep } from "@app/products/property/assessments/components/form-steps/reactivate-assessment/components/form-elements/associations-parcel/_index";
import { SelectTitlesFormStep } from "@app/products/property/assessments/components/form-steps/reactivate-assessment/components/form-elements/select-titles/_index";
import {
  DTO_Address,
  DTO_WorkflowDetail_ReactivateAssessment,
  DTO_Workflow_ReactivateAssessment,
  RequestObjFromSpatial,
} from "@app/products/property/assessments/components/form-steps/reactivate-assessment/model";
import { useReactivateAssessmentDialogStore } from "@app/products/property/assessments/components/form-steps/reactivate-assessment/store";
import { WorkflowGroupButtonsSpatial } from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial";
import { IButtonSpatial } from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial/model";
import { useWorkflowGroupButtonsSpatialStore } from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial/store";
import {
  getNotificationSpatialWorkflow,
  isDisabledApproveButton,
  isHiddenRejectButton,
  isSpatialGISMode,
} from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial/util";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmReallocateDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { CollectionFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/_index";
import { useAssessmentCollectionStepStore } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/store";
import { collectionUtilProcessing } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/util";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { WorkflowStepFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/_index";
import {
  getSuffixTitle,
  getWorkflowInstructions,
  secondaryWorkflowUtilProcessing,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/util";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import {
  IListDialog,
  IProcessWorkflow,
  WorkflowApprovalStatus,
} from "@app/products/property/components/action-bar/property-workflow/model";
import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { eComponent } from "@app/products/property/components/associations/model";
import { useCollectionsStore } from "@app/products/property/components/child-screen/collections/store";
import { PropertyDocumentEventType } from "@app/products/property/components/child-screen/documents/constant";
import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import { officerUtilProcessing } from "@app/products/property/components/fields/officer-and-officer-region/util";
import { GISAccordionEventType } from "@app/products/property/components/gis-accordion/constant";
import { ECustomColNameProperty } from "@app/products/property/config";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  ResponseMessageWorkflow,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IdentityPacket } from "@common/models/identityPacket";
import { CommunityProperty, Label } from "@common/stores/products/config";
import { useCommonProductStore } from "@common/stores/products/store";
import { getUUID } from "@common/utils/common";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { EBadgeDialogType, IBadgeDialog } from "@components/cc-dialog/model";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { Button } from "@progress/kendo-react-buttons";
import { head, isNil, pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface IReactivateAssessmentDialogProps {
  onClose: () => void;
  assessmentID: number;
  statusID: number;
  dataFromActionList?: VO_Workflow_Draft;
  prefixTitle?: string;
  isSaveOnNextStep?: boolean;
  suffixTitle?: string;
  isRedirectManagePage?: boolean;
}

export const ReactivateAssessmentDialog = observer(
  ({
    onClose,
    assessmentID,
    statusID,
    dataFromActionList,
    prefixTitle,
    isSaveOnNextStep = false,
    suffixTitle,
    isRedirectManagePage = false,
  }: IReactivateAssessmentDialogProps) => {
    //Store
    const { currentFormTitle } = useCommonProductStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const {
      setIsLoadingOnDialog,
      setReactivateAssessmentLOVs,
      reactivateAssessmentLOVs: assessmentLOVs,
    } = useReactivateAssessmentDialogStore();
    const { setAssessmentCollectionStepLOVs } =
      useAssessmentCollectionStepStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { reLoadAssessment, assessment } = useAssessmentStore();
    const { setListDialog } = usePropertyWorkflowStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { reloadCollections } = useCollectionsStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
    const { setDataForReallocateDialog } = useConfirmReallocateDialogStore();
    const { setDataForRejectDialog } = useConfirmRejectDialogStore();
    const { clearSelectedItems } = useCCProductListViewStore();
    const { buttonMapping, buttonFetch, setButtonMapping, setButtonFetch } =
      useWorkflowGroupButtonsSpatialStore();
    const history = useHistory();
    const params: { id: string } = useParams();
    const assessmentId: number = +params?.id || assessmentID;
    // Get status from props or from the summary api in manage page
    const statusId: number | undefined =
      assessment?.Assessment?.StatusId ?? statusID;

    const {
      isFromActionList,
      isIncompleteMode,
      isReadOnly,
      isShowCancelWorkflowButton,
      isToBeApprovalMode,
      statusBadge,
    } = usePropertyWorkflow(dataFromActionList);

    //Site
    const { isLLS, isActro } = CommunityProperty.getFlagOfStates();

    //State
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [isLoadingInStep, setIsLoadingInStep] = useState<boolean>(false);
    const [isLoadingApprove, setIsLoadingApprove] = useState<boolean>(false);
    const [isLoadingDialog, setIsLoadingDialog] = useState<boolean>(false);
    const [reactivateAssessmentInitData, setReactivateAssessmentInitData] =
      useState<DTO_Workflow_ReactivateAssessment>();
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);

    //Get labels
    const [titleLowercase, assessmentLabel] = Label.CommunityProperty.getLabel([
      ECustomColNameProperty.TitleLowercase,
      ECustomColNameProperty.Assessment,
    ]);

    const initialValue = useMemo(() => {
      const workflowDetail = reactivateAssessmentInitData?.WorkflowDetail;
      const workflowHeader = reactivateAssessmentInitData?.WorkflowHeader;

      const officerInitData = officerUtilProcessing.processDataInit(
        reactivateAssessmentInitData
      );
      let initDetails: any = {
        ...workflowDetail?.Details,
        Instructions: getWorkflowInstructions(
          workflowHeader?.WorkflowApprovals ?? []
        ),
        ...officerInitData,
      };
      let initPhysicalAddress: any = [];
      let cloneDetailGIS: DTO_GIS[] = [];
      if (workflowDetail?.AssessmentDetail?.GisReferences?.length) {
        cloneDetailGIS = workflowDetail?.AssessmentDetail?.GisReferences?.map(
          (item) => ({
            ...item,
            Id: getUUID(),
          })
        );
      }
      let initAssessmentDetail: any = {
        ...workflowDetail?.AssessmentDetail,
        CreationDate:
          workflowDetail?.AssessmentDetail?.CreationDate ?? new Date(),
        LocalityPFICode:
          workflowDetail?.AssessmentDetail?.AssessmentReference?.slice(
            0,
            3
          ).replaceAll("_", ""),
        GisReferences: cloneDetailGIS,
        Status: 0,
        OfficerIsAdministrator: workflowHeader?.OfficerIsAdministrator,
      };
      let initAssociation: any = {
        ...workflowDetail?.Association,
      };

      let clonePhysicalAddresses: DTO_Address[] = [];
      if (workflowDetail?.PhysicalAddresses?.length) {
        clonePhysicalAddresses = workflowDetail?.PhysicalAddresses.map(
          (item) => ({
            ...item,
            RowId: getUUID(),
          })
        );
      }
      initPhysicalAddress = [...clonePhysicalAddresses];

      //---step Secondary Workflow---
      const initSecondaryWorkflow =
        secondaryWorkflowUtilProcessing.processDataInit(
          reactivateAssessmentInitData
        );

      return {
        [EKeysOfSteps.Details]: initDetails,
        [EKeysOfSteps.PhysicalAddress]: initPhysicalAddress,
        [EKeysOfSteps.AssessmentDetail]: initAssessmentDetail,
        [EKeysOfSteps.Comments]: {},
        [EKeysOfSteps.Documents]: {},
        [EKeysOfSteps.SecondaryWorkflow]: initSecondaryWorkflow,
        [EKeysOfSteps.Collection]: collectionUtilProcessing.processDataInit<
          Collection[]
        >(reactivateAssessmentInitData),
        [EKeysOfSteps.Association]: initAssociation,
      };
    }, [reactivateAssessmentInitData]);

    const steps: IStep[] = [
      {
        label: "Details",
        initialValues: initialValue.Details,
        component: SelectTitlesFormStep,
        visible: true,
        key: EKeysOfSteps.Details,
        isValid: false,
        options: {
          isReadOnly,
          isToBeApprovalMode: isToBeApprovalMode,
          isActro,
          workflowDraftId,
        },
      },
      {
        label: `${assessmentLabel} Details`,
        id: `cc-step-label-${ECustomColNameProperty.Assessment}`,
        initialValues: initialValue.AssessmentDetail,
        component: AssessmentDetailsFormStep,
        visible: isActro,
        key: EKeysOfSteps.AssessmentDetail,
        options: {
          isReadOnly,
          isActro,
          isLLS,
        },
      },
      {
        label: "Related",
        initialValues: initialValue.Association,
        component: AssociationParcelsFormStep,
        visible: true,
        key: EKeysOfSteps.Association,
        options: {
          isReadOnly,
          isLLS,
          assessmentLabel,
          titleLowercase,
          setIsLoadingInStep,
        },
      },
      {
        label: "Physical address",
        initialValues: initialValue.PhysicalAddresses,
        component: PhysicalAddressGridFormStep,
        visible: true,
        key: EKeysOfSteps.PhysicalAddress,
        options: {
          isReadOnly,
          isLLS,
          setIsLoadingDialog,
          assessmentLOVs,
        },
      },
      {
        label: `${assessmentLabel} Details`,
        id: `cc-step-label-${ECustomColNameProperty.Assessment}`,
        initialValues: initialValue.AssessmentDetail,
        component: AssessmentDetailsFormStep,
        visible: !isActro,
        key: EKeysOfSteps.AssessmentDetail,
        options: {
          isReadOnly,
          isActro,
          isLLS,
        },
      },
      {
        label: "Collections",
        initialValues: initialValue[EKeysOfSteps.Collection],
        component: CollectionFormStep,
        visible: true,
        key: EKeysOfSteps.Collection,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Comments",
        initialValues: initialValue.Comments,
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfSteps.Comments,
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Assessment,
        },
        customClassName: "cc-comment-step-fixed-height-grid",
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        initialValues: initialValue.Documents,
        visible: true,
        key: EKeysOfSteps.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          componentId: assessmentID,
          workflowType: WorkflowTypes.Reactivate_Assessment,
        },
      },
      {
        label: "Workflow",
        initialValues: initialValue.SecondaryWorkflow,
        component: WorkflowStepFormStep,
        visible: true,
        key: EKeysOfSteps.SecondaryWorkflow,
        options: {
          isReadOnly,
          isFromActionList,
          dataFromActionList,
        },
      },
    ];

    const handleSubmit = (data: any, buttonId?: string) => {
      //reset notification before go next calling
      notificationFormStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.resetNotifications();
      switch (buttonId) {
        case EListSubmitButton.Approve:
          handleApproveProcess(processData(data));
          break;
        case EListSubmitButton.Save:
          sendSaveHolding(processData(data), true);
          break;
        case EListSubmitButton.SaveWorkflow:
          sendSaveHolding(processData(data), false);
          break;
        case EListSubmitButton.Finish:
          handleConfirmFinishProcess(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          handleConfirmRetainProcess(processData(data));
          break;
        case EListSubmitButton.SendBack:
          handleConfirmSendBackProcess(processData(data));
          break;
        case EListSubmitButton.Reallocate:
          handleConfirmReallocateProcess(processData(data));
          break;
        case EListSubmitButton.Reject:
          handleRejectButton(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          handleParkProcess(processData(data));
          break;
        case EListSubmitButton.MappingSpatialWorkflow:
          handleMappingSpatialProcess(processData(data));
          break;
        case EListSubmitButton.FetchSpatialWorkflow:
          handleFetchSpatialProcess(processData(data));
          break;
      }
    };

    /**
     * Handle reject process
     */
    const handleRejectButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForRejectDialog({
          rejectCallback: postProcessReactivateAssessment,
          dataReject: data,
          defaultSuccessMessage: `Reactivate ${assessmentLabel.toLowerCase()} application was rejected successfully`,
          defaultErrorMessage: `Reactivate ${assessmentLabel.toLowerCase()} reject failed`,
        });
      } else {
        onClose();
      }
    };

    /**
     * handle confirm send back workflow process
     * @param payload
     */
    const handleConfirmSendBackProcess = (
      payload: DTO_Workflow_ReactivateAssessment
    ) => {
      setDataForSendBackDialog({
        sendBackCallback: postProcessReactivateAssessment,
        dataSendBack: payload,
        defaultSuccessMessage: `Reactivate ${assessmentLabel.toLowerCase()} approval sent back successfully`,
        defaultErrorMessage: `Send reactivate ${assessmentLabel.toLowerCase()} back failed`,
      });
    };

    /**
     * handle confirm reallocate workflow process
     * @param payload
     */
    const handleConfirmReallocateProcess = (
      payload: DTO_Workflow_ReactivateAssessment
    ) => {
      setDataForReallocateDialog({
        reallocateCallback: postProcessReactivateAssessment,
        dataReallocate: payload,
        defaultSuccessMessage: `Reactivate ${assessmentLabel.toLowerCase()} approved successfully`,
        defaultErrorMessage: `Approve reactivate ${assessmentLabel.toLowerCase()} failed`,
      });
    };

    const processData: DTO_Workflow_ReactivateAssessment | any = (
      data: any
    ) => {
      //Officer process data
      //field update: WD_Assessment_Group_Id
      const { WD_Assessment_Group_Id: assessmentGroupIdWD } =
        officerUtilProcessing.processData(data, EKeysOfSteps.Details);
      workflowHeader.WorkflowDraft.WD_Assessment_Group_Id = assessmentGroupIdWD;
      let workflowDetail: any = {
        ContainsInActiveParcels:
          reactivateAssessmentInitData?.WorkflowDetail
            ?.ContainsInActiveParcels ?? false,
      };
      const sendSteps = pickBy(data, function (value, key) {
        if (keysOfSendSteps.includes(key as EKeysOfSteps)) {
          return { [key]: value };
        }
      });
      for (const [key, value] of Object.entries(sendSteps)) {
        let dataStep;
        if (
          key === EKeysOfSteps.PhysicalAddress ||
          key === EKeysOfSteps.Collection
        ) {
          dataStep = [...(value ?? [])];
        } else {
          dataStep = { ...value };
        }
        if (dataStep && dataStep?._option) {
          delete dataStep._option;
        }
        if (dataStep?.BuildingUnitNumber === "") {
          dataStep.BuildingUnitNumber = null;
        }
        if (key === EKeysOfSteps.SecondaryWorkflow) {
          secondaryWorkflowUtilProcessing.processData(
            value,
            workflowHeader,
            workflowDetail
          );
        } else {
          workflowDetail[key as keyof DTO_WorkflowDetail_ReactivateAssessment] =
            dataStep;
        }
        delete workflowDetail?.AssessmentDetail?.OfficerIsAdministrator;
      }
      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    /**
     * handle approve process
     * * @param payload
     */
    const handleApproveProcess = (
      payload: DTO_Workflow_ReactivateAssessment
    ) => {
      //set loading button
      setIsLoadingApprove(true);

      //props send to process workflow
      const approveProps: IProcessWorkflow<DTO_Workflow_ReactivateAssessment> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            clearSelectedItems();
            pushNotification({
              title:
                e?.Notification ??
                `Reactivate ${assessmentLabel.toLowerCase()} approved successfully`,
              type: "success",
            });
          },
          defaultFailedMessage: `Approve reactivate ${assessmentLabel.toLowerCase()} failed`,
          modeProcess: WorkflowProcessMode.Approve,
        };

      const setLoading = () => {
        setIsLoadingApprove(false);
      };
      //calling api process workflow
      handleProcessWorkflow(approveProps, setLoading);
    };

    /**
     * handle park process
     * @param payload
     */
    const handleParkProcess = (payload: DTO_Workflow_ReactivateAssessment) => {
      //set loading button and dialog
      setIsLoadingPark(true);
      setIsLoadingOnDialog(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_ReactivateAssessment> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title: e?.Notification ?? `${assessmentLabel} parked successfully`,
            type: "success",
          });
        },
        defaultFailedMessage: `Park ${assessmentLabel.toLowerCase()} failed`,
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => {
        setIsLoadingPark(false);
        setIsLoadingOnDialog(false);
      };

      //calling api process workflow
      handleProcessWorkflow(parkProps, setLoading);
    };

    /**
     * handle finish workflow process
     * @param payload
     */
    const handleConfirmFinishProcess = (
      payload: DTO_Workflow_ReactivateAssessment
    ) => {
      setDataForFinishDialog({
        finishCallback: async () =>
          await handleFinishProcess(
            processData(payload, WorkflowProcessMode.Finish)
          ),
        confirmMessage: `The ${assessmentLabel.toLowerCase()} will be modified based on the information provided. Are you sure you want to submit?`,
      });
    };

    /**
     * common function
     * handle calling api with multiple process
     * @param props
     */
    const handleProcessWorkflow = async (
      props: IProcessWorkflow<DTO_Workflow_ReactivateAssessment>,
      setLoading: () => void
    ) => {
      const { payload, actionSuccess, defaultFailedMessage, modeProcess } =
        props;
      let secondaryWorkflowSelectedList: IListDialog[] = [];
      if (modeProcess === WorkflowProcessMode.Finish) {
        if (payload.WorkflowDetail?.SecondaryWorkflowSelectedList) {
          secondaryWorkflowSelectedList =
            payload.WorkflowDetail.SecondaryWorkflowSelectedList ?? [];
          delete payload.WorkflowDetail.SecondaryWorkflowSelectedList;
        }
      }
      const response = await postProcessReactivateAssessment(
        modeProcess,
        payload
      );

      if (isSuccessResponse(response)) {
        setLoading();
        if (response?.data?.IsSuccess) {
          actionSuccess(response?.data);
          //Show secondary workflow
          if (
            modeProcess === WorkflowProcessMode.Finish &&
            secondaryWorkflowSelectedList?.length
          ) {
            setListDialog(secondaryWorkflowSelectedList);
          }
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    };

    /**
     * handle finish process
     * @param payload
     */
    const handleFinishProcess = async (
      payload: DTO_Workflow_ReactivateAssessment
    ) => {
      //props send to process workflow
      const finishProps: IProcessWorkflow<DTO_Workflow_ReactivateAssessment> = {
        payload: payload,
        actionSuccess: async (e) => {
          onClose();
          clearSelectedItems();
          eventEmitter.emit(CCGridEventType.RefreshOData);
          if (!isFromActionList) {
            //Refresh GIS Reference accordion if the workflow is auto approved
            if (e?.WorkflowApprovalStatus === WorkflowApprovalStatus.Approved) {
              eventEmitter.emit(GISAccordionEventType.reloadGISReferenceGrid);
            }

            //Refresh document and comment accordion after updated successfully
            eventEmitter.emit(PropertyDocumentEventType.RefreshData);
            eventEmitter.emit(CCGridEventType.RefreshOData, {
              gridIds: [COMMENT_ACCORDION_GRID_ID],
            });
            reloadCollections(eComponent.Assessment, assessmentID);
            await reLoadAssessment();
            if (
              (params?.id &&
                history?.location?.pathname ===
                  `${ASSESSMENT_ROUTE}/${params?.id}`) ||
              assessmentID
            ) {
              eventEmitter.emit(
                AssessmentTabDetailsEventType.RefreshData,
                parseInt(params?.id)
              );
            }
          }
          const notificationContent: IAppNotificationItemAddProps = {
            title:
              e?.Notification ??
              `Reactivate ${assessmentLabel.toLowerCase()} created successfully`,
            type: "success",
          };
          !isRedirectManagePage && pushNotification(notificationContent);
        },
        defaultFailedMessage: `Reactivate ${assessmentLabel.toLowerCase()} failed`,
        modeProcess: WorkflowProcessMode.Finish,
      };

      const setLoading = () => {};
      //calling api process workflow
      await handleProcessWorkflow(finishProps, setLoading);
    };

    const sendSaveHolding = async (
      payload: DTO_Workflow_ReactivateAssessment,
      isCloseDialog: boolean = false
    ) => {
      const response = await postProcessReactivateAssessment(
        WorkflowProcessMode.Save,
        payload
      );
      setIsLoadingOnNext(false);
      const defaultSuccessMessage = `${assessmentLabel} saved successfully`;
      const defaultFailedMessage = `The reactivate ${assessmentLabel.toLowerCase()} process could not be saved`;
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          if (isCloseDialog) {
            onClose();
            pushNotification({
              title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
              type: "success",
            });
          }
          if (isFirstSave) {
            setIsFirstSave(false);
            setWorkflowDraftId(response?.data?.ID ?? 0);
            //TODO: Don't need call after saving at create new
            //Update workflowHeader when creating new Workflow
            // if (workflowHeader?.WorkflowDraft?.Workflow_Draft_Id === 0) {
            //   getWorkflowData(response?.data?.ID ?? 0);
            // }
            setWorkflowHeader({
              ...workflowHeader,
              WorkflowDraft: {
                ...workflowHeader.WorkflowDraft,
                Workflow_Draft_Id: response?.data?.ID,
              },
            });
            // get new data from draftId after first save
            if (officerUtilProcessing.canReloadWorkflowData(isFromActionList)) {
              setReactivateAssessmentInitData({
                WorkflowDetail: response.data?.ReturnData?.WorkflowDetail,
                WorkflowHeader: response.data?.ReturnData?.WorkflowHeader,
              });
            }
          }
          return true;
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
            });
          return false;
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
          });
        return false;
      }
    };

    const handleNextButton = (data: any) => {
      setIsLoadingOnNext(true);
      const newData = processData(data);
      return sendSaveHolding(newData);
    };

    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title =
        currentFormTitle(formId ?? 0) || `Reactivate ${assessmentLabel}`;

      return getTitleWorkflow(
        title,
        prefixTitle,
        getSuffixTitle(
          suffixTitle,
          isToBeApprovalMode,
          workflowHeader?.WorkflowApprovals
        )
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefixTitle, suffixTitle, isToBeApprovalMode, workflowHeader]);

    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name &&
        dataFromActionList.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };

    /**
     * Handle cancel process
     * @param data
     */
    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessReactivateAssessment,
          dataCancel: data,
          defaultSuccessMessage: `Reactivate ${assessmentLabel.toLowerCase()} was cancelled successfully.`,
          defaultErrorMessage: `Reactivate ${assessmentLabel.toLowerCase()} could not be cancelled.`,
        });
      } else {
        onClose();
      }
    };

    /**
     * handle confirm retain workflow process
     * @param payload
     */
    const handleConfirmRetainProcess = (
      payload: DTO_Workflow_ReactivateAssessment
    ) => {
      //set loading button and dialog
      setIsLoadingClose(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_ReactivateAssessment> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title: e?.Notification ?? `${assessmentLabel} parked successfully`,
            type: "success",
          });
        },
        defaultFailedMessage: `Park ${assessmentLabel.toLowerCase()} failed`,
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog(undefined);
      };

      //calling api process workflow
      handleProcessWorkflow(parkProps, setLoading);
    };

    const getWorkflowData = () => {
      const id = dataFromActionList?.Workflow_Draft_Id;
      const requestObjPayload: RequestObjFromSpatial = {
        AssessmentId: assessmentId,
        StatusId: statusId,
      };
      notificationFormStepRef?.current?.setLoadingFormStep(true);
      getInitialDataAssessment(
        WorkflowTypes.Reactivate_Assessment,
        requestObjPayload,
        id
      ).then((response) => {
        if (Array.isArray(response)) {
          const [lovsAssessment, workflowData] = response;
          if (
            isSuccessResponse(lovsAssessment) &&
            isSuccessResponse(workflowData) &&
            lovsAssessment?.data &&
            workflowData?.data
          ) {
            //set Lovs Data Assessment
            setReactivateAssessmentLOVs({
              ...lovsAssessment?.data?.dtoCreate_Assessment_LOVs,
            });
            setAssessmentCollectionStepLOVs({
              Collection_Type:
                lovsAssessment?.data?.dtoCreate_Assessment_LOVs
                  ?.Collection_Type,
            });
            if (workflowData?.data) {
              //set Create Assessment InitData
              setReactivateAssessmentInitData({
                WorkflowDetail: workflowData.data?.WorkflowDetail,
                WorkflowHeader: workflowData.data?.WorkflowHeader,
              });
              if (workflowData.data?.WorkflowHeader) {
                //Get notification for Spatial
                const notificationSpatial = getNotificationSpatialWorkflow(
                  workflowData.data?.WorkflowHeader
                );
                if (!isNil(notificationSpatial)) {
                  if (notificationSpatial?.mapping) {
                    setButtonMapping((preState: IButtonSpatial) => ({
                      ...preState,
                      ...notificationSpatial?.mapping,
                    }));
                  }
                  if (notificationSpatial?.fetch) {
                    setButtonFetch((preState: IButtonSpatial) => ({
                      ...preState,
                      ...notificationSpatial?.fetch,
                    }));
                  }
                }
                setWorkflowHeader(workflowData.data?.WorkflowHeader);
                setWorkflowDraftId(
                  workflowData.data?.WorkflowHeader?.WorkflowDraft
                    ?.Workflow_Draft_Id ?? 0
                );
              }
            }
            notificationFormStepRef?.current?.setLoadingFormStep(false);
          } else {
            let responseError: APIResponse<
              | DTO_Assessment_LOVs
              | DTO_Workflow_ReactivateAssessment
              | ResponsePacket
            > = lovsAssessment;
            if (!isSuccessResponse(workflowData)) {
              responseError = workflowData;
            }
            notificationFormStepRef?.current?.setLoadingFormStep(false);
            notificationFormStepRef?.current?.setLoadFailedFormStep({
              onReload: () => getWorkflowData(),
              responseError: {
                status: responseError.status,
                error:
                  (responseError.data as ResponsePacket)?.Errors ??
                  "Load workflow failed",
              },
            });
          }
        } else {
          const responseError = response as APIResponse;
          notificationFormStepRef?.current?.setLoadingFormStep(false);
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error: "Load workflow failed",
            },
          });
        }
      });
    };

    /**
     * handle mapping button at Spatial
     * Call 2 apis (Process, Get)
     * @param payload
     */
    const handleMappingSpatialProcess = async (
      payload: DTO_Workflow_ReactivateAssessment
    ) => {
      //set loading dialog
      setIsLoadingDialog(true);
      //set info mapping
      setButtonMapping((preState: IButtonSpatial) => ({
        ...preState,
        isLoading: true,
      }));
      let mappingInfo: any = {};
      //Calling api process
      const responsePost = await postProcessReactivateAssessment(
        WorkflowProcessMode.Mapping,
        payload
      );
      if (isSuccessResponse(responsePost)) {
        const resSuccessPost =
          responsePost?.data as ResponseMessageWorkflow<DTO_Workflow_ReactivateAssessment>;
        if (resSuccessPost?.IsSuccess) {
          //open new tab spatial
          window.open(responsePost?.data?.Gis_Mapping_Launch_Url, "_blank");
          mappingInfo = {
            isLoading: false,
            loadSuccess: undefined,
            loadFail: undefined,
          };
          if (resSuccessPost?.Notification?.length) {
            mappingInfo.loadSuccess = {
              message: {
                messageTitle: resSuccessPost?.Notification,
              },
              type: EBadgeDialogType.SUCCESS,
            };
          }
          setWorkflowHeader(resSuccessPost?.ReturnData?.WorkflowHeader);
          setReactivateAssessmentInitData({
            WorkflowDetail: resSuccessPost?.ReturnData?.WorkflowDetail,
            WorkflowHeader: resSuccessPost?.ReturnData?.WorkflowHeader,
          });
        } else {
          mappingInfo = {
            isLoading: false,
            loadSuccess: undefined,
            loadFail: undefined,
          };
          if (resSuccessPost?.Notification?.length) {
            mappingInfo.loadFail = {
              message: {
                messageTitle: resSuccessPost?.Notification,
              },
              type: EBadgeDialogType.ERROR,
            };
          }
          if (resSuccessPost?.ErrorMessage?.length) {
            notificationFormStepRef?.current
              ?.getNotificationFormStep()
              ?.current?.pushNotification({
                title: resSuccessPost?.Notification?.length
                  ? resSuccessPost?.Notification
                  : "Mapping data failed.",
                description: resSuccessPost?.ErrorMessage,
                type: "error",
                autoClose: false,
              });
          }
        }
      } else {
        //TODO: fix type for temporary
        const responseFail = responsePost?.data as unknown as IdentityPacket;
        mappingInfo = {
          isLoading: false,
          loadSuccess: undefined,
          loadFail: undefined,
        };
        if (responseFail?.Errors?.length) {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: "Mapping data failed.",
              description: head(responseFail?.Errors),
              type: "error",
              autoClose: false,
            });
        }
      }
      setButtonMapping((preState: IButtonSpatial) => ({
        ...preState,
        ...mappingInfo,
      }));
      setIsLoadingDialog(false);
    };

    const handleFetchSpatialProcess = async (
      payload: DTO_Workflow_ReactivateAssessment
    ) => {
      //set loading dialog
      setIsLoadingDialog(true);
      //set info fetch
      setButtonFetch((preState: IButtonSpatial) => ({
        ...preState,
        isLoading: true,
        loadFail: undefined,
        loadSuccess: undefined,
      }));
      let fetchInfo: any = {};
      const responsePost = await postProcessReactivateAssessment(
        WorkflowProcessMode.Fetch,
        payload
      );
      if (isSuccessResponse(responsePost)) {
        const resSuccessPost =
          responsePost?.data as ResponseMessageWorkflow<DTO_Workflow_ReactivateAssessment>;
        if (resSuccessPost?.IsSuccess) {
          fetchInfo = {
            isLoading: false,
            loadSuccess: undefined,
            loadFail: undefined,
          };
          if (resSuccessPost?.Notification?.length) {
            fetchInfo.loadSuccess = {
              message: {
                messageTitle: resSuccessPost?.Notification,
              },
              type: EBadgeDialogType.SUCCESS,
            };
          }
          setWorkflowHeader(resSuccessPost?.ReturnData?.WorkflowHeader);
          setReactivateAssessmentInitData({
            WorkflowDetail: resSuccessPost?.ReturnData?.WorkflowDetail,
            WorkflowHeader: resSuccessPost?.ReturnData?.WorkflowHeader,
          });
        } else {
          fetchInfo = {
            isLoading: false,
            loadSuccess: undefined,
            loadFail: undefined,
          };
          if (resSuccessPost?.Notification?.length) {
            fetchInfo.loadFail = {
              message: {
                messageTitle: resSuccessPost?.Notification,
              },
              type: EBadgeDialogType.ERROR,
            };
          }
          if (resSuccessPost?.ErrorMessage?.length) {
            notificationFormStepRef?.current
              ?.getNotificationFormStep()
              ?.current?.pushNotification({
                title: resSuccessPost?.Notification?.length
                  ? resSuccessPost?.Notification
                  : "Fetch data failed.",
                description: resSuccessPost?.ErrorMessage,
                type: "error",
                autoClose: false,
              });
          }
        }
      } else {
        //TODO: fix type for temporary
        const resFailPost = responsePost?.data as unknown as IdentityPacket;
        fetchInfo = {
          isLoading: false,
          loadSuccess: undefined,
          loadFail: undefined,
        };
        if (resFailPost?.Errors?.length) {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: "Fetch data failed.",
              description: head(resFailPost?.Errors),
              type: "error",
              autoClose: false,
            });
        }
      }
      setButtonFetch((preState: IButtonSpatial) => ({
        ...preState,
        ...fetchInfo,
      }));
      setIsLoadingDialog(false);
    };

    const newBadge = useMemo(() => {
      if (!isNil(statusBadge)) return statusBadge;
      let newStatus: undefined | IBadgeDialog[];
      if (isSpatialGISMode(workflowHeader)) {
        newStatus = [];
        if (
          buttonMapping?.loadSuccess &&
          buttonMapping?.loadSuccess?.message?.messageTitle
        ) {
          newStatus.push({
            content:
              buttonMapping?.loadSuccess?.message?.messageTitle ??
              "Mapping in progress",
          });
        } else if (
          buttonMapping?.loadFail &&
          buttonMapping?.loadFail?.message?.messageTitle
        ) {
          newStatus.push({
            content:
              buttonMapping?.loadFail?.message?.messageTitle ??
              "Mapping in failed",
            type: EBadgeDialogType.ERROR,
          });
        }
        if (
          buttonFetch?.loadSuccess &&
          buttonFetch?.loadSuccess?.message?.messageTitle
        )
          newStatus.push({
            content:
              buttonFetch?.loadSuccess?.message?.messageTitle ??
              "Fetch data successful",
            type: EBadgeDialogType.SUCCESS,
          });
        else if (
          buttonFetch?.loadFail &&
          buttonFetch?.loadFail?.message?.messageTitle
        )
          newStatus.push({
            content:
              buttonFetch?.loadFail?.message?.messageTitle ??
              "Fetch data failed",
            type: EBadgeDialogType.ERROR,
          });
      }
      return newStatus;
    }, [statusBadge, workflowHeader, buttonMapping, buttonFetch]);

    useEffectOnce(() => {
      getWorkflowData();
    });

    return (
      <CCFormStep
        ref={notificationFormStepRef}
        listButtonId={listSubmitButton}
        onSubmit={handleSubmit}
        initialSteps={steps}
        initialValues={initialValue}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => {
          return (
            <CCDialog
              maxWidth="65%"
              disabled={isLoadingApprove || isLoadingInStep || isLoadingDialog}
              titleHeader={titleHeader}
              onClose={() => handleCloseDialog(renderProps)}
              badge={newBadge}
              bodyElement={renderProps.children}
              footerElement={
                <>
                  <div className={"cc-dialog-footer-actions-right"}>
                    {isShowParkButton(isFromActionList, isIncompleteMode) && (
                      <Button
                        iconClass={
                          isLoadingPark ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        id={EListSubmitButton.Park}
                        onClick={renderProps.submitButton.onClick}
                        disabled={
                          renderProps.nextButton.disabled || isLoadingPark
                        }
                      >
                        Park
                      </Button>
                    )}
                    {isShowCancelWorkflowButton && (
                      <Button
                        className={"cc-dialog-button"}
                        disabled={
                          isLoadingApprove || isLoadingOnNext || isLoadingPark
                        }
                        id={EListSubmitButton.Cancel}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Cancel
                      </Button>
                    )}

                    {isToBeApprovalMode &&
                      workflowHeader?.OfficerCanApprove && (
                        <>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.SendBack}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Send Back
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Reallocate}
                            disabled={renderProps.nextButton.disabled}
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                          >
                            Reallocate
                          </Button>
                          <Button
                            themeColor="primary"
                            id={EListSubmitButton.Approve}
                            disabled={
                              isLoadingApprove ||
                              renderProps.nextButton.disabled ||
                              isDisabledApproveButton(workflowHeader)
                            }
                            className={"cc-dialog-button"}
                            onClick={renderProps.submitButton.onClick}
                            iconClass={
                              isLoadingApprove ? "fas fa-spinner fa-spin" : ""
                            }
                          >
                            Approve
                          </Button>
                          {isHiddenRejectButton(workflowHeader) ? null : (
                            <Button
                              themeColor="primary"
                              id={EListSubmitButton.Reject}
                              disabled={renderProps.nextButton.disabled}
                              className={"cc-dialog-button"}
                              onClick={renderProps.submitButton.onClick}
                            >
                              Reject
                            </Button>
                          )}
                        </>
                      )}
                    {!renderProps.prevButton.disabled && (
                      <Button
                        className={"cc-dialog-button"}
                        themeColor="primary"
                        onClick={renderProps.prevButton.onClick}
                      >
                        Previous
                      </Button>
                    )}
                    {isToBeApprovalMode || isReadOnly ? (
                      !renderProps.isLastStep && (
                        <Button
                          themeColor="primary"
                          id="cc-next-step-button"
                          disabled={
                            isLoadingOnNext || renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          iconClass={
                            isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                          }
                          onClick={renderProps.nextButton.onClick}
                        >
                          {isLoadingOnNext
                            ? "Saving"
                            : renderProps.nextButton.label}
                        </Button>
                      )
                    ) : (
                      <Button
                        themeColor="primary"
                        id={renderProps.nextButton.idButton}
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )}
                  </div>
                  <div className="cc-dialog-footer-actions-left">
                    <WorkflowGroupButtonsSpatial
                      workflowHeader={
                        reactivateAssessmentInitData?.WorkflowHeader
                      }
                      actionSubmit={renderProps.nextButton.onClick}
                    />
                  </div>
                </>
              }
            />
          );
        }}
      />
    );
  }
);
