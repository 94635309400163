import {
  dynamicOrganisationModeContact,
  EORGANISATION_NAME_TYPE,
  isShowFieldsPersonStep,
} from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/person/util";
import {
  ComboboxSearchAPI,
  IEventOnChangeComboBox,
} from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/components/combobox-search-api/_index";
import { EDeliveryOptions } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/config";
import { VO_JobDescription } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/model";
import { checkTypeContact } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/postal-and-physical-address/util";
import {
  EKeysOfNewContactSteps,
  MODE_CONTACT,
} from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import { nameOfLov } from "@app/products/property/model";
import { convertValueLOVToNumber } from "@app/products/property/util";
import { DTO_LOV } from "@common/models/odataResponse";
import { getDropdownValue } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import React, { useMemo } from "react";
import "./_index.scss";

export const PersonFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({
  formRenderProps,
  nameOf,
  options = {
    isReadOnly: false,
    isLLS: false,
    contactLOVs: undefined,
    setIsDisableDialog: () => {},
    keyOfPostalStep: "PortalAddress",
  },
}: IFormStepElement) => {
  //LOVs
  const contactLovs = useMemo(() => {
    const type = convertValueLOVToNumber<DTO_LOV>(
      options?.contactLOVs?.Type ?? [],
      nameOfLov("Code")
    );
    const category = convertValueLOVToNumber<DTO_LOV>(
      options?.contactLOVs?.Category ?? [],
      nameOfLov("Code")
    );
    const bodyTypes = convertValueLOVToNumber<DTO_LOV>(
      options?.contactLOVs?.Body_Types ?? [],
      nameOfLov("Code")
    );
    const distinction = options?.contactLOVs?.Distinction ?? [];
    const title = options?.contactLOVs?.PersonTitle ?? [];
    return {
      type,
      category,
      distinction,
      title,
      bodyTypes,
    };
  }, [options?.contactLOVs]);

  //props
  const { valueGetter, onChange } = formRenderProps;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFieldValue = (name: string) => valueGetter(nameOf(name));

  //get data field
  const typeValue = getFieldValue("Type");
  const deliveryValue = valueGetter(`${options.keyOfPostalStep}.Delivery`);

  //Export ref
  if (options?.onChangeRef) {
    options.onChangeRef.current = { onChange, nameOf };
  }

  const dynamicOrganisationName = useMemo(() => {
    return dynamicOrganisationModeContact(typeValue);
  }, [typeValue]);

  const dynamicOrganisationNameType = useMemo(() => {
    const typeValueContact = checkTypeContact(typeValue);
    switch (typeValueContact) {
      case MODE_CONTACT.INCORPORATED_BODY:
        return EORGANISATION_NAME_TYPE.NAME_OF_BODY;
      case MODE_CONTACT.PARTNERSHIP:
        return EORGANISATION_NAME_TYPE.PARTNERSHIP_NAME;
      default:
        return EORGANISATION_NAME_TYPE.ORGANISATION_NAME;
    }
  }, [typeValue]);

  const onChangeType = (event: ComboBoxChangeEvent) => {
    onChange(nameOf("Type"), {
      value: event.value?.Code ?? null,
    });
    const typeValueContact = checkTypeContact(event.value?.Code);
    if (
      typeValueContact === MODE_CONTACT.ORGANISATION ||
      typeValueContact === MODE_CONTACT.INCORPORATED_BODY ||
      typeValueContact === MODE_CONTACT.PARTNERSHIP
    ) {
      onChange(`${EKeysOfNewContactSteps.Attributes}.Occupation`, {
        value: null,
      });
      onChange(`${EKeysOfNewContactSteps.Attributes}.Gender`, {
        value: null,
      });
      onChange(`${EKeysOfNewContactSteps.Attributes}.Aboriginal`, {
        value: null,
      });
      onChange(`${EKeysOfNewContactSteps.Attributes}.HasCommunityGroup`, {
        value: null,
      });
      onChange(`${EKeysOfNewContactSteps.Attributes}.Community_Group_Ids`, {
        value: null,
      });
    }
  };

  return (
    <div className="cc-person-step">
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Type" isMandatory />
            <Field
              name={nameOf("Type")}
              component={CCSearchComboBox}
              data={contactLovs.type}
              textField={nameOfLov("Name")}
              dataItemKey={nameOfLov("Code")}
              onChange={(event: ComboBoxChangeEvent) => {
                onChangeType(event);
              }}
              disabled={options?.isReadOnly}
              validator={!options?.isReadOnly ? requiredValidator : undefined}
            />
          </div>
          {checkTypeContact(typeValue) === MODE_CONTACT.INCORPORATED_BODY ? (
            <div className="cc-field">
              <CCLabel title="Body type" isMandatory />
              <Field
                name={nameOf("Body_Type")}
                component={CCSearchComboBox}
                data={contactLovs.bodyTypes}
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                isUseDefaultOnchange
                validator={!options?.isReadOnly ? requiredValidator : undefined}
                disabled={options?.isReadOnly}
              />
            </div>
          ) : (
            <div className="cc-field">
              <CCLabel title="Category" />
              <Field
                name={nameOf("Category_Id")}
                component={CCSearchComboBox}
                data={contactLovs.category}
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                isUseDefaultOnchange
                disabled={options?.isReadOnly}
              />
            </div>
          )}
        </div>
        {checkTypeContact(typeValue) === MODE_CONTACT.PERSON && (
          <>
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Title" />
                <Field
                  name={nameOf("Title")}
                  textField={nameOfLov("Name")}
                  dataItemKey={nameOfLov("Code")}
                  data={contactLovs.title}
                  component={CCSearchComboBox}
                  isUseDefaultOnchange
                  disabled={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Given names" />
                <Field
                  name={nameOf("GivenName")}
                  component={CCInput}
                  placeholder={"Given names"}
                  readOnly={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Surname" isMandatory />
                <Field
                  name={nameOf("Surname")}
                  component={CCInput}
                  placeholder={"Surname"}
                  readOnly={options?.isReadOnly}
                  validator={
                    !options?.isReadOnly ? requiredValidator : undefined
                  }
                />
              </div>
            </div>
            <div className="cc-field">
              <CCLabel title="Distinction" />
              <Field
                name={nameOf("Distinction")}
                component={CCSearchComboBox}
                disabled={options?.isReadOnly}
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                data={contactLovs.distinction}
                value={getDropdownValue(
                  valueGetter(nameOf("Distinction")),
                  contactLovs.distinction,
                  nameOfLov(nameOfLov("Code"))
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  onChange(nameOf("Distinction"), {
                    value: event.value?.Code ?? null,
                  });
                }}
              />
            </div>
          </>
        )}
        {isShowFieldsPersonStep(typeValue) && (
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel title={dynamicOrganisationNameType} isMandatory />
              <Field
                name={nameOf("Organisation_Name")}
                component={CCInput}
                placeholder={dynamicOrganisationNameType}
                readOnly={options?.isReadOnly}
                validator={!options?.isReadOnly ? requiredValidator : undefined}
              />
            </div>
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <CCLabel title="ACN" />
                <Field
                  name={nameOf("ACN")}
                  component={CCInput}
                  placeholder={"ACN"}
                  readOnly={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="ABN" />
                <Field
                  name={nameOf("ABN")}
                  component={CCInput}
                  placeholder={"ABN"}
                  readOnly={options?.isReadOnly}
                />
              </div>
            </div>
          </div>
        )}
      </section>
      <br />
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-form-cols-1">
            {isShowFieldsPersonStep(typeValue) &&
              deliveryValue !== EDeliveryOptions.DXMail && (
                <div className="cc-field">
                  <CCLabel title="Attention of" />
                  <Field
                    name={nameOf("AttentionOf")}
                    component={CCInput}
                    placeholder={"Attention of"}
                    readOnly={options?.isReadOnly}
                  />
                </div>
              )}
            {deliveryValue !== EDeliveryOptions.DXMail && (
              <>
                <div className="cc-field">
                  <CCLabel title="Care of" />
                  <Field
                    name={nameOf("CareOf")}
                    component={CCInput}
                    placeholder={"Care of"}
                    readOnly={options?.isReadOnly}
                  />
                </div>
                <div className="cc-field">
                  <CCLabel title="Job description" />
                  <Field
                    name={nameOf("_option.Job_Description")}
                    textField="Job_Description"
                    dataItemKey="Job_Description"
                    urlAPI={`/odata/property/internal/entityjobdescriptionRegister`}
                    component={(event) =>
                      ComboboxSearchAPI<VO_JobDescription>(event)
                    }
                    keySearch={"Job_Description"}
                    disabled={options?.isReadOnly}
                    data={getFieldValue("_option.Job_Description_Data")}
                    onChange={(event: IEventOnChangeComboBox) => {
                      onChange(nameOf("Job_Description"), {
                        value: event?.value?.Job_Description,
                      });
                      onChange(nameOf("_option.Job_Description"), {
                        value: event?.value?.Job_Description,
                      });
                      onChange(nameOf("_option.Job_Description_Data"), {
                        value: event?.data ?? [],
                      });
                    }}
                    allowCustom
                  />
                </div>
                <div className="cc-field">
                  <CCLabel title={dynamicOrganisationName} />
                  <Field
                    name={nameOf("Organisation")}
                    component={CCInput}
                    placeholder={dynamicOrganisationName}
                    readOnly={options?.isReadOnly}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};
