import { DTO_Attributes } from "@app/products/property/contacts-central-names/[id]/components/child-screens/general/components/further-details/model";
import { DTO_Entity } from "@app/products/property/contacts-central-names/[id]/model";
import { useContactsCentralNamesStore } from "@app/products/property/contacts-central-names/[id]/store";
import { dynamicOrganisationModeContact } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/person/util";
import { isFieldVisible } from "@app/products/property/util";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCLabel } from "@components/cc-label/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
const nameOf = nameOfFactory<DTO_Entity>();
const nameOfAttribute = nameOfFactory<DTO_Attributes>();
export const FormGeneral = observer(() => {
  const { pushNotification } = useCCAppNotificationStore();
  const { contact, attribute } = useContactsCentralNamesStore();
  const validateForm = (
    event: React.SyntheticEvent<any>,
    formRenderProps: FormRenderProps
  ) => {
    const { modified, valid, onSubmit } = formRenderProps;
    if (modified) {
      onSubmit(event);
      return;
    }
    if (!valid) return;
    pushNotification({
      title: "The form is not modified",
      type: "warning",
    });
  };
  return (
    <Form
      initialValues={contact?.Entity}
      key={JSON.stringify(contact?.Entity)}
      render={(formRenderProps: FormRenderProps) => {
        const { valueGetter } = formRenderProps;
        const contactType = valueGetter(nameOf("Type"));
        return (
          <FormElement>
            <input
              hidden
              type="submit"
              id="btn-submit"
              onClick={(e: React.SyntheticEvent<any>) =>
                validateForm(e, formRenderProps)
              }
            />
            <div className="cc-form">
              <div className="cc-field-group">
                <div className="cc-form-cols-2">
                  <div className="cc-field">
                    <label className="cc-label">Postal address</label>
                    <Field
                      name={nameOf("NameAddress_Formatted")}
                      rows={4}
                      component={CCTextArea}
                      placeholder="Postal address"
                      readOnly
                    />
                  </div>
                  {isFieldVisible(
                    contact?.Entity?.PhysicalAddressVisibility
                  ) && (
                    <div className="cc-field">
                      <label className="cc-label">Physical address</label>
                      <Field
                        name={nameOf("PhysicalAddress")}
                        rows={4}
                        component={CCTextArea}
                        placeholder="Physical address"
                        readOnly
                      />
                    </div>
                  )}
                </div>
                <div className="cc-field">
                  <CCValueField
                    label="Contact type"
                    value={contact?.Entity?.[nameOf("Type")]}
                  />
                </div>
                <div className="cc-field">
                  <CCValueField
                    label={dynamicOrganisationModeContact(contactType)}
                    value={contact?.Entity?.[nameOf("Organisation")]}
                  />
                </div>
                <div className="cc-form-cols-2">
                  {isFieldVisible(attribute?.AlsoKnowAsVisibility) && (
                    <div className="cc-field">
                      <CCLabel title="Alias" />
                      <CCTextArea
                        name={nameOfAttribute("AlsoKnownAs")}
                        placeholder="Alias"
                        value={attribute?.AlsoKnownAs}
                        rows={2}
                        readOnly
                      />
                    </div>
                  )}
                </div>
                <div className="cc-field-group">
                  <label className="cc-label">Contact information</label>
                  <div className="cc-custom-sub-panel-bar cc-form-cols-3">
                    {isFieldVisible(
                      contact?.Entity?.PhoneNumbersVisibility
                    ) && (
                      <>
                        <CCValueField
                          label="Business"
                          value={
                            contact?.Entity?.[nameOf("PhoneNumberBusiness")]
                          }
                        />
                        <CCValueField
                          label="Mobile"
                          value={contact?.Entity?.[nameOf("PhoneNumberMobile")]}
                        />
                        <CCValueField
                          label="Private"
                          value={
                            contact?.Entity?.[nameOf("PhoneNumberPrivate")]
                          }
                        />
                        <CCValueField
                          label="Fax"
                          value={contact?.Entity?.[nameOf("FaxNumber")]}
                        />
                      </>
                    )}
                    {isFieldVisible(contact?.Entity?.EmailVisibility) && (
                      <CCValueField
                        label="Email"
                        value={contact?.Entity?.[nameOf("Email")]}
                      />
                    )}
                    <div className="cc-field">
                      <CCValueField
                        label="Preferred name"
                        value={contact?.Entity?.[nameOf("Preferred_Name")]}
                      />
                    </div>
                    <CCValueField
                      label="Preferred contact method"
                      value={contact?.Entity?.[nameOf("Preferred_Method")]}
                    />
                  </div>
                </div>
              </div>
              <br />
            </div>
          </FormElement>
        );
      }}
    />
  );
});
