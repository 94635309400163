import { FinanceTransactionsAccordion } from "@app/core/transaction/finance-transaction-accordion/model";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<FinanceTransactionsAccordion>();
export const colFinanceTransactionsAccordion: IColumnFields[] = [
  {
    field: nameOf("ID"),
    title: "ID",
    linkTo: (dataItem) => {
      if (dataItem.FinanceSystemTransactionURL !== dataItem.ID)
        return dataItem.FinanceSystemTransactionURL;
      return undefined;
    },
  },
  {
    field: nameOf("RecordedDate"),
    title: "Recorded",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("RecordedBy"),
    title: "Recorded By",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("TransactionType"),
    title: "Transaction Type",
  },
  {
    field: nameOf("InvoiceNo"),
    title: "Invoice No",
  },
  {
    field: nameOf("Amount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Balance"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];
